import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import moment from "moment";
import { MdClose } from "react-icons/md";

import styles from "./index.module.scss";
import { post } from "services/axiosCalls";
import { API } from "services/urlConstants";
import { putStatus } from "services/Vehicle/vehicle.service";
import { successToast } from "helpers/toastFunctions";


const ModalDelivery = ({  modalDelivery, setModalDelivery, date, recallDocuments,setModalUnsigned }) => {

  const retirar = async () => {
  
    if (modalDelivery.document.url || modalDelivery.document.documentNameCloud){
    await putStatus(modalDelivery.document.vehicle_id_stock, 4)
    await post(API.ADD_CAR_DELIVERY_DATE, {
      date: moment(date).format("YYYY/MM/DD HH:mm:ss"),
      id:modalDelivery.document.documentId,
    });

    successToast(<span>Fecha actualizada</span>)
    recallDocuments()
    setModalDelivery(false)
    }
    else {
      setModalUnsigned(true)
    }
  }
  const programar = async () => {
    await putStatus(modalDelivery.document.vehicle_id_stock, 3)
    await post(API.ADD_CAR_DELIVERY_DATE, {
      date: moment(date).format("YYYY/MM/DD HH:mm:ss"),
      id: modalDelivery.document.documentId,
    });

    successToast(<span>Fecha actualizada</span>)
    recallDocuments()
    setModalDelivery(false)
  }

  return (
    <>
      <Modal
        size="large"
        centered
        className="modal-lg"
        isOpen={modalDelivery?.type=== "today"}
      >

        <ModalBody className="p-4">

          <center><strong>Has marcado el día de HOY como fecha de entrega.</strong></center>

          <br /><strong>Retirar Vehículo:</strong> se procederá a la retirada inmediata del vehículo del stock (web, portales, etc).

          <br /><strong>Programar entrega:</strong> se programará la entrega para hoy, el vehículo aparecerá con la entrega "Pte. de confirmación"


          </ModalBody> <ModalFooter>
          <Button color="primary" onClick={() => retirar()}>
            Retirar Vehículo
          </Button>  {" "}
          <Button
            color="secondary"
            onClick={() => programar()}
          >
            Programar entrega
          </Button>
        </ModalFooter>
        <div className={styles.close} onClick={() => setModalDelivery(false)}>
          <MdClose title="Cerrar"  size={30} />
        </div>
      </Modal>
      <Modal
        size="large"
        centered
        className="modal-lg"
         isOpen={modalDelivery?.type=== "past"}
      >
        <ModalHeader>Retirada de vehículo</ModalHeader>
        <ModalBody className="p-4">
          <center>
            La fecha de entrega es anterior al día actual. Se procederá a la retirada inmediata del vehículo del stock (web, portales, etc).
            <br />
            ¿Está seguro de que desea retirar el vehículo?

          </center>

        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={() => retirar()}>
            Retirar Vehículo
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => setModalDelivery(false)}
          >
            Cancelar
          </Button>
        </ModalFooter>
        <div onClick={() => setModalDelivery(false)} className={styles.close}>

          <MdClose title="Cerrar" size={30} />
        </div>
      </Modal>
   
    </>
  );
};

export default ModalDelivery