import { Input } from "reactstrap";
import React, { useState, useEffect } from "react";
import { putImageCategory, putImages } from "services/Vehicle/vehicle.service";
import { errorToast } from "helpers/toastFunctions";
import { MdDeleteForever } from "react-icons/md";
import { useTranslation } from 'react-i18next';

const Preview = ({
  getImagesPreviewUrls,
  imagesPreviewUrls,
  deleteImage,
  vehicle_id,
  type,
}) => {
  const { t } = useTranslation('vehicleValidation');
  const [state, setState] = useState({
    previewImages: [],
    dragId: "",
  });
  useEffect(() => {
    if (imagesPreviewUrls !== state.previewImages) {
      setState({ ...state, previewImages: imagesPreviewUrls });
    }
  }, [imagesPreviewUrls]);

  const handleOver = (ev) => {
    ev.preventDefault();
  };

  const handleDrag = (ev) => {
    setState({ ...state, dragId: ev.currentTarget.id });
  };

  const handleDrop = (ev) => {
    ev.preventDefault();
    const { previewImages } = state;
    const { dragId } = state;
    const dragImage = previewImages.find((image) => image.order_id == dragId);
    const dropImage = previewImages.find(
      (image) => image.order_id == ev.currentTarget.id
    );

    const arr = moveItem(dragImage.order_id - 1, dropImage.order_id - 1);

    setState({
      ...state,
      previewImages: arr,
    });
    const order = arr.map((item) => item.id);
    if (order) {
      try {
        putImages(vehicle_id, order, type);
      } catch (e) {
        errorToast(e);
      }
    }
  };
  const moveItem = (from, to) => {
    const { previewImages } = state;
    const f = previewImages.splice(from, 1)[0];
    previewImages.splice(to, 0, f);
    return previewImages;
  };
  useEffect(() => {
    renderPreview();
  }, [state.dragId]);
  const handleChange = async (e, id, image_id) => {
    let previewImages = [...state.previewImages];
    let image = { ...previewImages[id - 1] };
    image.labelId = Number(e.target.value);
    previewImages[id - 1] = image;
    try {
      await setState({ ...state, previewImages: previewImages });
      await putImageCategory(vehicle_id, image_id, image.labelId, type);
    } catch (e) {
      errorToast(e);
    }
  };
  const renderPreview = () => {
    const { previewImages } = state;

    if (previewImages.length > 0) {
      previewImages.map((items, index) => {
        items.order_id = index + 1;
      });
    }
  };

  return (
    <>
      <div className="wrapper">{renderPreview()}</div>
      <p>
        {t('imagesOrder')}
      </p>
      <div
        className=" row masonry-grid"
        data-masonry='{"percentPosition": true }'
      >
        {state.previewImages.length > 0 &&
          state.previewImages.map((element, index) => {
            return (
              <div
                className={
                  element.order_id === 1 ? "gallery principal" : "gallery"
                }
                key={index}
                id={element.order_id}
                draggable
                onDragOver={(e) => handleOver(e)}
                onDragStart={(e) => handleDrag(e)}
                onDrop={(e) => handleDrop(e)}
                style={{ cursor: "move" }}
              >
                {element.type?.includes("video") ? (
                  <>
                    <span className={"principal"}>Video</span>
                    <video
                      controls="controls"
                      src={element.url}
                      width="220px"
                      height="120px"
                    />
                  </>
                ) : (
                  <>
                    <span
                      className={
                        element.order_id === 1 ? "principal" : "no-display"
                      }
                    >
                      Principal
                    </span>
                    <img src={element.url} alt={element.name} />
                  </>
                )}

                <Input
                  placeholder={"Categoria"}
                  type="select"
                  className={
                    element.labelId !== null && element.labelId !== 0
                      ? "form-control ds-input mt-1 pointer selected"
                      : "form-control ds-input mt-1 pointer"
                  }
                  value={element.labelId}
                  onChange={(e) => {
                    handleChange(e, element.order_id, element.id);
                  }}
                >
                  <option value={0}>Añadir categoria</option>
                  <option value={2}>Exterior</option>
                  <option value={3}>Interior</option>
                </Input>

                <div className="desc">
                  <div className="image-order">
                    <MdDeleteForever
                      className="delete-icon"
                      onClick={() => deleteImage(element.id)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Preview;
