import { Table } from "reactstrap";
import moment from "moment";

import styles from "./index.module.scss";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import ModalWarning from "components/ModalWarning";
import { getSignUrl } from "services/axiosCalls";
import { errorToast, successToast } from "helpers/toastFunctions";
import { useCallback, useContext, useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import {
  deletePreparation,
  getPreparationComments,
  getTotalAmountPreparation,
  updatePreparationComment,
} from "services/Preparation/preparation.service";
import { MdEdit } from "react-icons/md";
import FormattedNumberInput from "components/FormattedNumberInput";
import PaginationFooter from "components/PaginationFooter";
import { useParams } from "react-router-dom";
import { getCurrentPage } from "helpers/currentPage";
import Loading from "views/Loading/Loading";
import { UserContext } from "contexts/UserContext";


const PreparationTable = ({vehicle}) => {
  const { id: vehicleId } = useParams();
  const { local } = useContext(UserContext);

  const [offset, setOffset] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [totalComments, setTotalComments] = useState(0);
  const [viewingDocument, setViewingDocument] = useState(false);
  const [warningAlert, setWarningAlert] = useState(false);
  const [commentDelete, setCommentDelete] = useState();
  const [deleteAction, setDeleteAction] = useState();
  const [vehicleTotalAmount, setVehicleTotalAmount] = useState(0);

  const paginationLimit = 8;

  const loadComments = useCallback(async () => {
    try {
      const commentsResponse = await getPreparationComments({
        page: actualPage,
        size: paginationLimit,
        vehicleId: vehicleId,
      });
      setComments(
        commentsResponse.results.map((x) => ({ ...x, id_vehiculo: vehicleId }))
      );
      setTotalComments(commentsResponse.total);
    } catch (err) {
      errorToast(err?.response?.data?.message || "");
    }
  }, [actualPage, vehicleId]);

  const loadCommentTotalAmount = useCallback(async () => {
    try {
      const amountResponse = await getTotalAmountPreparation(vehicleId);
      setVehicleTotalAmount(amountResponse.totalAmount);
    } catch (err) {
      errorToast(
        err?.response?.data?.message ||
          "Error al conseguir el coste total del vehiculo."
      );
    }
  }, [vehicleId]);

  const deleteDocument = async (comment) => {
    try {
      const response = await updatePreparationComment(comment.id, {
        title: comment.title,
        amount: comment.amount || 0,
        comment: comment.comment || null,
        fileAction: "DELETE",
      });
      if (response.status === 204) loadComments();
    } catch (err) {
      errorToast("Error al eliminar el documento");
    }
  };

  useEffect(() => {
    (async () => {
      await loadComments();
      await loadCommentTotalAmount();

      setIsLoading(false);
    })();
  }, [loadComments, loadCommentTotalAmount]);

  const changePagination = (min, num) => {
    setOffset(min);
    const actualPage = getCurrentPage(min, paginationLimit, totalComments);
    setActualPage(actualPage);
  };
  if (isLoading) return <Loading />;

  const handleDeleteComment = async (comment) => {
    try {
      const response = await deletePreparation(comment.id);
      if (response.status === 204) {
        if (comments.length === 1 && actualPage !== 1) {
          setActualPage(actualPage - 1);
          successToast("Comentario borrado con éxito");
          return;
        }
        loadComments();
        loadCommentTotalAmount();
        successToast("Comentario borrado con éxito");
      }
    } catch (err) {
      errorToast("Error al eliminar registro.");
    }
  };

  const handleOkButtonClick = () => {
    if (deleteAction && commentDelete) {
      deleteAction(commentDelete);
    }
    setDeleteAction(null);
    setCommentDelete(null);
    setWarningAlert(false);
  };
  const handleWarningAlert = (comment, action) => {
    setWarningAlert(!warningAlert);
    setCommentDelete(comment);
    setDeleteAction(() => action);
  };
  const openCommentFile = async (fileId) => {
    setViewingDocument(fileId);
    try {
      const {
        data: { url },
      } = await getSignUrl(fileId);
      window.open(url, "_blank", "noopener,noreferrer");
    } catch (err) {
      errorToast("Error al cargar el archivo");
    } finally {
      setViewingDocument(null);
    }
  };

  return (
    <>
      <span className={styles.vehicleTotalAmount}>
        Coste total del vehículo:{" "}
        <FormattedNumberInput value={vehicleTotalAmount} displayType="text" />
      </span>
      <div className={styles.commentsTable}>
        <Table className={`table table-borderless`}>
          <thead>
            <tr>
              <th>Título</th>
              <th>Comentario</th>
              <th width={120}>Importe</th>
              <th width={120}>Acciones</th>
            </tr>
          </thead>

          <tbody>
            {comments.map((mComment, idx) => (
              <>
                <tr
                  key={`case-body-tr${idx}`}
                  className={`${mComment.file && styles.document} ${
                    idx % 2 === 0 ? styles.even : styles.odd
                  }`}
                >
                  <td data-label="Título">
                    <span>{mComment.title}</span>
                  </td>
                  <td data-label="Comentario">
                    <span>{mComment.comment}</span>
                  </td>
                  <td data-label="Importe" className={styles.amount}>
                    <FormattedNumberInput
                      value={mComment.amount}
                      displayType="text"
                      className="font-weight-bold"
                    />
                  </td>
                  <td>  
                  {( local.includes(vehicle.id_local) &&
                    <div className={styles.groupIcon}>
                      <a
                        className="mr-2"
                        href={`/main/vehicle/${mComment.id_vehiculo}/registro-preparacion/${mComment.id}`}
                      >
                        <MdEdit size={24} />
                      </a>
                      <RiDeleteBin6Line
                        className="pointer"
                        size={24}
                        onClick={() =>
                          handleWarningAlert(mComment, handleDeleteComment)
                        }
                      />
                    </div> )}
                  </td>
                </tr>
                {mComment.file && (
                  <tr
                    className={`${styles.documentRow} ${
                      idx % 2 === 0 ? styles.even : styles.odd
                    }`}
                  >
                    <td colSpan="4">
                      <div className={styles.gridContainer}>
                        <div
                          className={`${styles.gridItem} ${styles.fullWidth}`}
                        >
                          <span>
                            <strong>{mComment.file.title}</strong>
                          </span>
                        </div>
                        <div className={styles.gridItem}>
                          <span>
                            <strong>
                              {moment(mComment.file.uploadedAt).format(
                                "DD-MM-YYYY"
                              )}
                            </strong>
                          </span>
                        </div>
                        <div className={styles.gridItem}>
                          {viewingDocument === mComment.file.cloudId ? (
                            <FaSpinner className={styles.spinner} size={24} />
                          ) : (
                            <AiOutlineEye
                              className="pointer mr-2"
                              size={24}
                              onClick={() =>
                                openCommentFile(mComment.file.cloudId)
                              }
                              color="#307DF6"
                            />
                          )}
                           {( 
                          <RiDeleteBin6Line
                            className="pointer"
                            size={24}
                            onClick={() =>
                              handleWarningAlert(mComment, deleteDocument)
                            }
                          />)}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </Table>
        <ModalWarning
          okButton={handleOkButtonClick}
          cancelButton={handleWarningAlert}
          open={warningAlert}
        />
      </div>
      <PaginationFooter
        paginationMin={offset}
        total={totalComments}
        showNumb={paginationLimit}
        elements={comments}
        changePagination={changePagination}
        actualPage={actualPage}
      />
    </>
  );
};

export default PreparationTable;
