
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import WarningDialog from 'components/WarningDialog';

const RouteLeavingGuard = ({
    when,
    shouldBlockNavigation,
}) => {
    const history = useHistory();
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleBlockedNavigation = (nextLocation) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };

    useEffect(() => {
        
        if (confirmedNavigation && lastLocation) {
            const searchParams = new URLSearchParams(lastLocation.search);
            const newPathname = `${lastLocation.pathname}?${searchParams.toString()}`;
            history.push(newPathname);
        }
    }, [confirmedNavigation, lastLocation, history]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <WarningDialog
                open={modalVisible}
                titleText="Guardar los datos"
                contentText="Vas a salir de la página sin guardar los cambios, ¿quieres continuar?"
                cancelButtonText="Cancelar"
                confirmButtonText="Aceptar"
                onCancel={closeModal}
                onConfirm={handleConfirmNavigationClick}
            />
        </>
    );
};

export default RouteLeavingGuard;
