import React, { useState, useEffect } from "react";
import { post } from "../../../services/axiosCalls";
import { API } from "../../../services/urlConstants";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styles from "./index.module.scss";
import moment from "moment";

const ModalMensajesLead = ({
  lead_id,
  vehicle_id,
  client_id,
  user_id,
  setModal,
  status,
}) => { 
  const [mensajes, setMensajes] = useState([]);
  const [nuevoMensaje, setNuevoMensaje] = useState("");
  useEffect(() => {
    if(lead_id && status){
      getData();
    }
  }, [status]);

  const getData = async ()  => {
    const response = await post(API.GET_MENSAJES_LEAD, {lead_id, client_id});
    setMensajes(response)
  };

  const saveMesaje = async (e)  => {
    if(!e || e === 13){
      const response = await post(API.SAVE_MENSAJE_LEAD, {lead_id, client_id, vehicle_id, user_id, nuevoMensaje});
      if(response){
        getData();
        setNuevoMensaje("")
      }
    };
  }

  return (
    <Modal
    className="modal-lg"
    centered
    isOpen={status}
    toggle={() => setModal()}
  >
    <ModalHeader>Comentarios del Lead</ModalHeader>
    <ModalBody className="p-4">
    {mensajes.length != 0 ?
      mensajes.map((i) => {
        return <div class="text-right mt-3">
          <span className={`${styles.commentBlock}`}>{i.comentario}</span><img className="pointer" style={{width:"39px"}} title={i.nombre + " el " + moment(i.fecha).format("DD-MM-YYYY HH.mm")} src="https://storage.googleapis.com/crm_public_files/public/logo192.png"/>
        </div>
      })
      :
      "Ningún mensaje encontrado"}
      <hr className="mt-5" />
      <textarea className="w-100" value={nuevoMensaje} onChange={(e)=>setNuevoMensaje(e.target.value)} onKeyDown={(e)=>saveMesaje(e.keyCode)}></textarea>
      
    </ModalBody>
    <ModalFooter>
      <button type="button" class="btn btn-light btn-outline-dark btn-flexicar" onClick={() => saveMesaje()}>Enviar</button>
      <button type="button" class="btn btn-light btn-outline-dark btn-flexicar" onClick={() => setModal()}>Cerrar</button>
    </ModalFooter>
  </Modal>
  );
};

export default ModalMensajesLead;