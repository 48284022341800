export const SELL_FILTER_STATE = Object.freeze({
	APPOINTED: "citados",
	WON: "ganados",
	ABSENCE: "no asiste",
	ARCHIVED: "archivados",
	MANAGING: "en gestión"
});

export const sellFiltersConfig = [
	{
		filterLabel: "Citados",
		filter: SELL_FILTER_STATE.APPOINTED,
		color: "#f0a23c"
	},
	{
		filterLabel: "Ganado",
		filter: SELL_FILTER_STATE.WON,
		color: "#ff6252"
	},
	{
		filterLabel: "No asiste / No interesa",
		filter: SELL_FILTER_STATE.ABSENCE,
		color: "#82a2dd"
	},
	{
		filterLabel: "Archivado / Dudoso",
		filter: SELL_FILTER_STATE.ARCHIVED,
		color: "#2ecaac"
	},
	{
		filterLabel: "En gestión",
		filter: SELL_FILTER_STATE.MANAGING,
		color: "#bfbfbf"
	}
];

export const SELL_LEAD_STATES = Object.freeze({
	appointed: [12],
	absence: [5, 6, 32],
	won: [44, 28, 2],
	archived: [13, 3]
});

export const getSellColorByLeadState = (leadState) => {
	if (SELL_LEAD_STATES.appointed.includes(leadState)) return "#f0a23c";
	else if (SELL_LEAD_STATES.won.includes(leadState)) return "#ff6252";
	else if (SELL_LEAD_STATES.absence.includes(leadState)) return "#82a2dd";
	else if (SELL_LEAD_STATES.archived.includes(leadState)) return "#2ecaac";
	return "#bfbfbf";
};

export const getSellTypeNameByLeadState = (event) => {
	const leadState = event.lead.state;

	if (SELL_LEAD_STATES.appointed.includes(leadState)) return SELL_FILTER_STATE.APPOINTED;
	else if (SELL_LEAD_STATES.won.includes(leadState)) return SELL_FILTER_STATE.WON;
	else if (SELL_LEAD_STATES.absence.includes(leadState)) return SELL_FILTER_STATE.ABSENCE;
	else if (SELL_LEAD_STATES.archived.includes(leadState)) return SELL_FILTER_STATE.ARCHIVED;
	return SELL_FILTER_STATE.MANAGING;
};
