import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const WarningDialog = ({
  open,
  titleText,
  contentText,
  cancelButtonText,
  confirmButtonText,
  onCancel,
  onConfirm,
}) => {
  if (!open) {
    return null;
  }

  return (

    <div>
      <Modal isOpen={open}>
        <ModalHeader>{titleText}</ModalHeader>
        <ModalBody>
          {contentText}
        </ModalBody>
        <ModalFooter>
          <Button className="btn-flexicar-orange" onClick={onConfirm}>
            {confirmButtonText}
          </Button>
          <Button className="btn-flexicar-inverse-size-s" type="button" onClick={onCancel}>
            {cancelButtonText}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );


};

export default WarningDialog;