import { useCallback, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { post } from "services/axiosCalls";

const ejs = require("ejs");
const RenderTemplateEjs = ({ data, templateId, show, setShow }) => {
	const templateRef = useRef(null);

	const reset = () => {
		setShow(false);
		const wrapper = document.getElementById("templateWrapper");
		if (wrapper) {
			wrapper.innerHTML = ""; // Borra el contenido del elemento
		}
	};

	const handlePrint = useReactToPrint({
		content: () => templateRef.current,
		onAfterPrint: () => reset()
	});

	const renderTemplate = useCallback(async () => {
		const response = await post("/api/template", { template: templateId });
		const html = response[0]?.temp;
		const renderedTemplate = await ejs.render(html, data, { async: true });
		templateRef.current.innerHTML = renderedTemplate.toString();
		handlePrint();
	}, [data, handlePrint, templateId]);

	useEffect(() => {
		// Llama a renderTemplate solo cuando show sea true
		show && renderTemplate();
	}, [renderTemplate, show]);

	return (
		<div style={{ display: "none" }}>
			<div
				ref={templateRef}
				className="m-5"
				id="templateWrapper"
			/>
		</div>
	);
};
export default RenderTemplateEjs;
