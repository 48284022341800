import React, { useEffect, useRef } from "react";
import { get, post } from "../../services/axiosCalls";
import { Button } from "reactstrap";
import { errorToast } from "../../helpers/toastFunctions";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
const Ejs = () => {
  let ejs = require("ejs");
  const { t } = useTranslation('ejs');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const factura_id = urlParams.get("factura");
    document.title = `Flexicar | ${factura_id ? factura_id : "Documento"}`;
    const factura_fecha = urlParams.get("fecha");
    if (factura_id && factura_fecha) {
      getTemplate(factura_id, factura_fecha);
    } else {
      getTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTemplate = async (factura_id, factura_fecha) => {
    const urlParam = window.location.pathname.split("/");
    const id_contrato = urlParam[urlParam.length - 1];
    if (!factura_id) {
      const response = await post("/api/ejs", { id_contrato });
      mergeTemplate(response.length > 0 ? response[0].temp : null, id_contrato);
    } else {
      const response = await get("/api/ejsFactura");
      mergeTemplate(response[0].temp, id_contrato, factura_id, factura_fecha);
    }
  };

  const mergeTemplate = async (
    template,
    id_contrato,
    factura_id,
    factura_fecha
  ) => {
    try {
      const data = await post("/api/ejs_data", { id_contrato });
      let data_template = data[0].datos;
      if (factura_id && factura_fecha) {
        data_template["factura_numero"] = factura_id;
        data_template["factura_fecha"] =
          moment(factura_fecha).format("DD-MM-YYYY");
      } else {
        data_template["fecha"] = data[0].fecha;
        data_template["fecha_modificacion"] = data[0].fecha_modificacion;
      }

      document.getElementById("template").innerHTML = ejs.render(template, {
        data: data_template,
      });
    } catch {
      return errorToast(<span>{t("errors.errorContractExists")} {id_contrato}</span>);
    }
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <Button
        className="btn-flexicar mt-4 mr-5 right no-print"
        onClick={() => handlePrint()}
      >
        Imprimir contrato
      </Button>
      {/*       <Button
        className="btn-flexicar mt-4 mr-5 right no-print"
        onClick={() => window.print()}
      >
        Firmar
      </Button> */}
      <br />
      <div className="m-5" ref={componentRef} id="template"></div>
    </div>
  );
};

export default Ejs;
