import React, { useState, useEffect, Fragment, useContext } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Spinner } from "reactstrap";

import Pagination from "./components/Pagination";
import styles from "./index.module.scss";
import TableStock from "./components/TableStock";
import { dispatcher } from "../../redux/actions/dispatchers";
import errorCall from "services/errorCall";
import { useDebouncedEffect } from "../../helpers/useDebouncedEffect";
import { getDrafts } from "services/Vehicle/vehicle.service";
import { UserContext } from "contexts/UserContext";
import { useTranslation } from "react-i18next";
const Stock = ({ vehicle_search, history, vehicleSearch }) => {
  const [callBackSetState, setCallBackSetState] = useState({
    searchCars: false,
    filter: false,
    filterList_Km: false,
    filterList_Euros: false,
    filterList_Days: false,
    filterList_Anio: false,
  });
  const { rol } = useContext(UserContext);
  
  const [brandDisabled, setBrandDisabled] = useState(true);
  const [brandList, setBrandList] = useState([0]);
  const [cars, setCars] = useState([]);
  const [totalCars, setTotalCars] = useState(0);
  const [paginationMin, setPaginationMin] = useState(0);
  const [showNumb, setShowNumb] = useState(25);
  const [searchValue, setSearchValue] = useState("");
  const [showAvg, setShowAvg] = useState(false);
  
  const { t } = useTranslation('vehicleValidationList');

  const [state, setState] = useState({
    filterIVA: { label: "", value: "" },
    filterDisponible: true,
    filterReservado: true,
    filterHistorical: {
      label: rol?.some((e) => e === 18 || e === 19) ? "aplica_historico" : "",
      value: rol?.some((e) => e === 18 || e === 19) ? true : false,
    },
    filterList: [],
    filterDays: [],
    filterAnio: [],
    filterKm: [],
    filterEuros: [],
    filters: {
      Marcas: [],
      Modelos: [],
      Locales: [],
      Provincias: [],
      Carroceria: [],
      Combustible: [],
      Transmision: [],
      Plazas: [],
      EmpresaStock: [],
    },
    km: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    anio: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    euros: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    days_in_stock: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
     cv: {
      smaller: { value: "", label: "" },
      greater: { value: "", label: "" },
    },
    // brandList: [0],
    // brandDisabled: true,
    modal_check: false,
    modal_add: false,
    modal_conflict: false,
    checkLead: {
      toggle: false,
      vehicle_id: null,
      vehicle: null,
      nombre: "",
      telefono: "",
      cliente: null,
      id_existente_lead: null,
      condiciones: false,
      comercial: false,
      provincia: "",
      municipio: "",
      estado: "",
      origen: "",
      forma_contacto: "",
      observaciones: "",
      email: "",
      telefono_secundario: "",
      apellido_1: "",
      apellido_2: "",
    },
  });

  const [avg, setAvg] = useState();

  let page = paginationMin / showNumb + 1;
  //Montaje de la página con consulta sobre todos los coches en Stock
  useEffect(() => {
    if (callBackSetState.searchCars === true) {
      if (vehicle_search != null) {
        const ant_val = vehicle_search;
        setSearchValue(ant_val.searchValue);
        setState(ant_val);
        setPaginationMin(ant_val.paginationMin);
        setShowNumb(ant_val.showNumb);
        renderFilterList(ant_val);
        vehicleSearch("");
      } else {
        document.title = "Flexicar | Validación de vehiculo";
        const loadCars = async () => {
          try {
            const dataCall = {
              historical: "",
              filters: "",
              states: "",
              data: "",
              min: paginationMin,
              limit: showNumb,
              showAvg,
              page: page,
              size: showNumb,
              order: "date",
              orderDir: "DESC",
            };
            const drafts = await getDrafts(dataCall);
            await setCars(drafts?.results);
            await setTotalCars(drafts?.total);
          } catch (e) {
            errorCall(e);
          }
        };

        loadCars();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callBackSetState]);

  //Comprobamos los permisos de usuario activar el acceso en STATE

  //Paginación recibiendo 'valor de inicio' y 'número de valores por página'
  const changePagination = (min, num) => {
    setPaginationMin(min);
    setShowNumb(num);
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  //Cambial el estado de filterList, que renderizará una lista con los filtros
  //aplicados en cada Componente sobre el input de búsqueda
  const renderFilterList = (prevState) => {
    const selectedState = prevState ? prevState : state;
    let filterList = [];
    for (let key in selectedState.filters) {
      if (selectedState.filters[key].length > 0) {
        selectedState.filters[key].forEach((v, i) => {
          filterList.push(v.label);
        });
      }
    }
    setState({ ...selectedState, filterList: filterList });
  };

  const renderFilterListSelected = (stateName, option) => {
    let filter = [];
    for (let key in state[option]) {
      if (state[option][key].label.length > 0) {
        filter.push(state[option][key].label);
      }
    }
    setState({ ...state, [stateName]: filter });
  };

  //Lanza la consulta de búsqueda al servidor
  const searchCars = async (searchInfo, prevState) => {
    const selectedState = prevState ? prevState : state;

    try {
      const dataCall = {
        historical: selectedState.filterHistorical.value,
        // filters: sqlFilters,
        // states: sqlState,
        // data: search_elements,
        min: paginationMin,
        limit: showNumb,
        showAvg,
        plate: searchValue,
        page: 1,
        size: showNumb,
        order: "date",
        orderDir: "DESC",
      };

      const response = await getDrafts(dataCall);
      setCallBackSetState({ ...callBackSetState, searchCars: false });

      setCars(response.results);
      setTotalCars(response.total);
    } catch (e) {
      errorCall(e);
    }
  };

  //Lanza la búsqueda de stock al rellenar el input
  const handleChange = (event) => {
    setPaginationMin(0);
    setSearchValue(event.target.value);
  };

  useDebouncedEffect(
    () => searchValue?.length > 6 && searchCars(searchValue),
    300,
    [searchValue]
  );

  //Recibe data de los componentes Filter
  const getData = (filter, value) => {
    setState({
      ...state,
      filters: {
        ...state.filters,
        [filter]: value,
      },
    });
    setPaginationMin(0);

    setCallBackSetState({ searchCars: true, filter: true });
  };

  //Recibe data del filtro Crear Lead sin Vehículo
  const getData_Create = (filter, value) => {
    launchModal("modal_check", value[0].value, true);
  };

  //Recibe data de los componentes Filter_From_To
  const getData_From_To = (filter, value, refr) => {
    let direction = "";
    if (filter === ">") {
      direction = "greater";
    } else if (filter === "<") {
      direction = "smaller";
    }
    if (state.days_in_stock[direction] !== "" && value.length > 0) {
      setState({
        ...state,
        [refr]: {
          ...state[refr],
          [direction]: { value: value[0].value, label: value[0].label },
        },
      });

      setCallBackSetState({
        ...callBackSetState,
        searchCars: true,
        filterList_Km: true,
        filterList_Euros: true,
        filterList_Anio: true,
      });
    } else {
      setState({
        ...state,
        [refr]: {
          ...state[refr],
          [direction]: { value: "", label: "" },
        },
      });
      setCallBackSetState({
        ...callBackSetState,
        searchCars: true,
        filterList_Km: true,
        filterList_Euros: true,
        filterList_Anio: true,
      });
    }
  };

  //Recibe data de los componentes FilterDaysInStock
  const getData_Days = (filter, value) => {
    let direction = "";
    if (filter === ">") {
      direction = "greater";
    } else if (filter === "<") {
      direction = "smaller";
    }

    if (state.days_in_stock[direction] !== "" && value.length > 0) {
      // let union = state.days_in_stock + " and " + value[0].value;

      setState({
        ...state,
        days_in_stock: {
          ...state.days_in_stock,
          [direction]: { value: value[0].value, label: value[0].label },
        },
      });
      setCallBackSetState({
        ...callBackSetState,
        searchCars: true,
        filterList_Days: true,
      });
    } else if (state.days_in_stock[direction] === "" && value.length > 0) {
      setState({
        ...state,
        days_in_stock: {
          ...state.days_in_stock,
          [direction]: { value: value[0].value, label: value[0].label },
        },
      });
      setCallBackSetState({
        ...callBackSetState,
        searchCars: true,
        filterList_Days: true,
      });
    } else {
      setState({
        ...state,
        days_in_stock: {
          ...state.days_in_stock,
          [direction]: { value: "", label: "" },
        },
      });
    }
    setCallBackSetState({
      ...callBackSetState,
      searchCars: true,
      filterList_Days: true,
    });
  };

  //Recibe data de los componentes FilterCheckbox
  const getData_Disponible = (value, filter, query) => {
    setState(() => ({ ...state, [filter]: value }));
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  const getData_Reservado = (value, filter, query) => {
    setState(() => ({ ...state, [filter]: value }));
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  const getData_IVA = (value, filter, query) => {
    setState(() => ({ ...state, [filter]: { label: query, value } }));
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  const getData_Historical = (value, filter, query) => {
    setState(() => ({ ...state, [filter]: { label: query, value } }));
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  //Recibe data de los componentes Checkbox
  const getData_CheckBox = (value, filter) => {
    setState({
      ...state,
      checkLead: {
        ...state.checkLead,
        [filter]: value,
      },
    });
  };

  //Recibe data de los componentes Dropdown_Dc
  const getDataAdd = (filter, value) => {
    setState({
      ...state,
      checkLead: {
        ...state.checkLead,
        [filter]: value[0],
      },
    });
  };

  //Elimina valores en el renderizado de filtros
  const quitValue = (val, idx) => {
    setState({
      ...state,
      filterList: state.filterList.filter((_, i) => i !== idx),
    });
    for (let key in state.filters) {
      if (state.filters[key].length > 0) {
        state.filters[key].forEach(function (v, i) {
          if (v.label === val) {
            const keyDeletedRes = state.filters[key].filter(
              (item) => item.label !== val
            );
            state.filters[key] = keyDeletedRes;
            // forceUpdate();
          }
        });
      }
    }
  };

  //Elimina valores en el renderizado de filtros
  const quitValueDoubleFilter = (val, idx, label, filter) => {
    setState({ ...state, [label]: state[label].filter((_, i) => i !== idx) });

    for (let key in state[filter]) {
      if (state[filter][key].label === val) {
        state[filter][key] = { value: "", label: "" };
        // forceUpdate();
      }
    }
  };

  const quitValueCheckFilter = (filter) => {
    setState({ ...state, [filter]: { label: "", value: "" } });
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  //Reset de filtros y stock
  const reset = () => {
    setSearchValue("");
    setPaginationMin(0);
    setState({
      ...state,
      filterIVA: { label: "", value: "" },
      filterDisponible: true,
      filterReservado: true,
      filterHistorical: { label: "", value: "" },
      filters: {
        Marcas: [],
        Modelos: [],
        Locales: [],
        Carroceria: [],
        Provincias: [],
        Combustible: [],
        Transmision: [],
        Plazas: [],
      },
      km: {
        smaller: { value: "", label: "" },
        greater: { value: "", label: "" },
      },
      anio: {
        smaller: { value: "", label: "" },
        greater: { value: "", label: "" },
      },

      euros: {
        smaller: { value: "", label: "" },
        greater: { value: "", label: "" },
      },
      days_in_stock: {
        smaller: { value: "", label: "" },
        greater: { value: "", label: "" },
      },
    });
    setCallBackSetState({ ...callBackSetState, searchCars: true });
  };

  const launchModal = (modal, vehicle_id, change) => {
    setState({
      ...state,
      [modal]: change,
      checkLead: {
        ...state.checkLead,
        vehicle: vehicle_id,
        cliente: null,
      },
    });
  };

  const handleValues = (e, field, type) => {
    const val = e.target.value;
    setState({
      ...state,
      [type]: {
        ...state[type],
        [field]: val,
      },
    });
  };
  const loadVehiclePage = (id) => {
    let data = state;
    data.searchValue = searchValue;

    data.paginationMin = paginationMin;
    data.showNumb = showNumb;

    vehicleSearch(data);
    history.push(`/main/validacion-vehiculo/${id}`);
  };

  const renderFilterListRender = state.filterList.map((d, idx) => (
    <span className={styles.classFilterList} key={d}>
      {d}{" "}
      <span
        className="react-dropdown-select-option-remove pointer"
        onClick={() => quitValue(d, idx)}
      >
        ×
      </span>
    </span>
  ));

  const renderFilterListSelectedRender = (stateName, option) =>
    state[stateName].map((d, idx) => (
      <span className={styles.classFilterList} key={d}>
        {d}{" "}
        <span
          className="react-dropdown-select-option-remove pointer"
          onClick={() => quitValueDoubleFilter(d, idx, stateName, option)}
        >
          ×
        </span>
      </span>
    ));

  const renderFilterList_IVA_Render = state.filterIVA.value ? (
    <span className={styles.classFilterList} key={"IVAdeducible"}>
      {" "}
      IVA deducible
      <span
        className="react-dropdown-select-option-remove pointer"
        onClick={() => quitValueCheckFilter("filterIVA")}
      >
        ×
      </span>
    </span>
  ) : (
    ""
  );

  const renderFilterList_Historical_Render = state.filterHistorical.value ? (
    <span className={styles.classFilterList} key={"Historical"}>
      {" "}
      Histórico{" "}
      <span
        className="react-dropdown-select-option-remove pointer"
        onClick={() => quitValueCheckFilter("filterHistorical")}
      >
        ×
      </span>
    </span>
  ) : (
    ""
  );

  useEffect(() => {
    if (
      state.modal_check === false &&
      state.modal_add === false &&
      state.modal_conflict === false
    ) {
      document.body.classList.remove("modal-open");
    }
  }, [state.modal_check, state.modal_add, state.modal_conflict]);
  useEffect(() => {
    if (searchValue.length === 0) {
      searchCars();
    }
  }, [searchValue]);

  return (
    <div className="App">
      {cars ? (
        <Fragment>
          <Container className="themed-container mt-4" fluid={true}>
            <Row>
              <Col className="col-12 col-md-12">
                <div className={`clearfix mt-3 mb-3 ${styles.container2}`}>
                  <span className="tittle ml-4">{t("vehiclesValidation")}</span>
                </div>
              </Col>
              <Col className="col-md-12 col-xs-6">
                <Row className="mb-1 float-right">
                  <Col xs="6">
                    {renderFilterListRender}{" "}
                    {renderFilterListSelectedRender(
                      "filterDays",
                      "days_in_stock"
                    )}{" "}
                    <div className={`${styles.webkitBox}`}>
                      <input
                        autoComplete="off"
                        placeholder={t("vehiclesValidation")}
                        className={`form-control ds-input mt-1 ${styles.w90}`}
                        type="text"
                        value={searchValue}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </Col>
                  <Col xs="4" className="text-right">
                    <Pagination
                      changePagination={changePagination}
                      total={totalCars}
                      showNumb={showNumb}
                    />
                  </Col>
                  <Col xs="2" className="text-right pr-5">
                    <span className="text-success">
                      {t("vehiclesValidation")}:{" "}
                    </span>
                    <span className="text-primary">{totalCars}</span>
                  </Col>
                </Row>

                <TableStock
                  cars={cars}
                  totalCars={totalCars}
                  setTotalCars={setTotalCars}
                  paginationMin={paginationMin}
                  showNumb={showNumb}
                  rol={rol}
                  loadVehiclePage={loadVehiclePage}
                  changePagination={changePagination}
                  launchModal={launchModal}
                  page={page}
                  setCallBackSetState={setCallBackSetState}
                  callBackSetState={callBackSetState}
                />
              </Col>
            </Row>
          </Container>
        </Fragment>
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <Spinner type="grow" className="flexicarColor" />
          <p className="flexicarColor"> CARGANDO Vehiculos...</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  vehicle_search: store.auth.vehicle_search,
});

const mapDispatch = dispatcher(["vehicleSearch"]);

export default connect(mapStateToProps, mapDispatch)(withRouter(Stock));
