import { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Col, Container, Row } from "reactstrap";

import styles from "./index.module.scss";

const MainlayoutSidebar = ({ children, hide = true }) => (
	<Col className={`${hide ? "d-none" : ""} d-xl-block col-xl-2 col-12 mb-5 mb-xl-0`}>
		<div className={styles.sidebar_container}>{children}</div>
	</Col>
);

const MainLayoutContent = ({ contentTitle, buttons, full = false, children }) => {
	const parentRef = useRef(null);
	const titleRef = useRef(null);
	const buttonsRef = useRef(null);

	// +14px del padding del parent
	// +4px del border y +16px del padding del title
	const parentPadding = 14;
	const border = 4;
	const titlePadding = 16;

	const [isCollapsed, setIsCollapsed] = useState(false);
	const [isMenuOpened, setIsMenuOpened] = useState(false);

	const [titleMinWidth, setTitleMinWidth] = useState(0);
	const [buttonsMinWidth, setButtonsMinWidth] = useState(0);

	const detectButtonsCollision = useCallback(() => {
		setIsCollapsed(parentRef.current.scrollWidth <= titleMinWidth + buttonsMinWidth);
	}, [titleMinWidth, buttonsMinWidth]);

	useEffect(() => {
		setTitleMinWidth(titleRef.current.scrollWidth + parentPadding + border + titlePadding);
		setButtonsMinWidth(buttonsRef.current.scrollWidth);
		// Depende de contenTitle por si no estan con su valor en el primer render
		// Por ejemplo si content title viene de una llamada async
	}, [contentTitle]);

	useEffect(() => {
		detectButtonsCollision();
		window.addEventListener("resize", detectButtonsCollision);

		return () => {
			window.removeEventListener("resize", detectButtonsCollision);
		};
	}, [detectButtonsCollision]);

	return (
		<div className={`${full ? "col-12" : "col-xl-10"} ${styles.main_content}`}>
			<div
				ref={parentRef}
				className={styles.title_container}
			>
				<span
					ref={titleRef}
					className={`${styles.title} tittle`}
					style={{ minWidth: isCollapsed ? "85%" : "unset" }}
				>
					{contentTitle}
				</span>
				<div
					ref={buttonsRef}
					className={styles.buttons}
					style={{ minWidth: buttonsMinWidth }}
				>
					{!isCollapsed && buttons}
				</div>

				{isCollapsed && (
					<div
						className={styles.menuButton}
						onClick={() => setIsMenuOpened((open) => !open)}
					>
						{isMenuOpened ? (
							<>
								<AiOutlineClose
									size={22}
									color="#fff"
								/>
								<div className={styles.popoverMenuButtonWrapper}>
									<div className={styles.popoverMenuButton}>{buttons}</div>
								</div>
							</>
						) : (
							<AiOutlineMenu
								size={22}
								color="#fff"
							/>
						)}
					</div>
				)}
			</div>

			<div className={styles.content_container}>{children}</div>
		</div>
	);
};

const Mainlayout = ({ children }) => (
	<Container
		className="mt-4"
		fluid
	>
		<Row className="m-auto justify-content-center">{children}</Row>
	</Container>
);

Mainlayout.Sidebar = MainlayoutSidebar;
Mainlayout.Content = MainLayoutContent;
export default Mainlayout;
