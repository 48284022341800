import React, { useState, useEffect } from "react";

import { Label, Input, FormGroup, Collapse } from "reactstrap";
import styles from "./index.module.scss";

import { BsChevronCompactDown, BsChevronCompactUp } from "react-icons/bs";

const ExtrasCategory = ({
  extras,
  setExtras,
  sendData,
  parentValues,
  state,
  vehiculo,
  setVehiculo,
  dontShowColapse,
  setRetailPrice,
  retailPrice,
  price,
  setPrice,
  extrasSelected,
  setExtrasSelected,
  extrasOld,
  saveExtras,
}) => {
  const [filterValues, setFilterValues] = useState([]);
  const [isActive, setActive] = useState(false);

  const [extraCollapseState, setExtraCollapseState] = useState([]);

  const toggleClass = (index) => {
    setActive(!isActive);
    const e = document.getElementById(index);
    if (isActive) {
      e.className = `${styles.accordionButton} ${styles.accordionButtonCollapse}`;
    } else {
      e.className = `${styles.accordionButton}`;
    }
  };

  const toggle = () => setIsOpen(!isOpen);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (parentValues && parentValues !== filterValues) {
      setFilterValues(parentValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentValues]);

  useEffect(() => {
    if (extras) {
      check();
      setExtraCollapseState(extras.map(() => false));
    }
  }, [extras]);

  useEffect(() => {
    setExtrasSelected([]);
  }, [vehiculo.version]);

  const selectionCheck = async (elm) => {
    if (extras?.length === 0) {
      extrasSelected.push(elm.id);
    } else if (extrasSelected.find((e) => e === elm.id)) {
      const remove = extrasSelected.indexOf(
        extrasSelected.find((e) => {
          if (e === elm.id) {
            return true;
          } else {
            return false;
          }
        })
      );
      extrasSelected.splice(remove, 1);
    } else {
      extrasSelected.push(elm.id);
    }
    setVehiculo({ ...vehiculo, extras: extrasSelected });
  };

  const check = async () => {
    await extras?.map((tipo) => {
      tipo.items.map((elm) => {
        if (
          elm.selected === true &&
          !extrasSelected.find((e) => e === elm.id)
        ) {
          extrasSelected.push(elm.id);
          extrasOld.push(elm.id);
        }
      });
    });
  };

  const handleCollapse = (index) => {
    let collapseCopy = [...extraCollapseState];
    collapseCopy[index] = !collapseCopy[index];
    setExtraCollapseState(collapseCopy);
  };

  const renderExtra = (type, index) => (
    <>
      <div
        style={{ cursor: "pointer" }}
        className="d-flex justify-content-between align-items-center"
        onClick={(e) => {
          e.stopPropagation();
          handleCollapse(index);
          toggleClass(`toggler${index}`);
        }}
      >
        <div
          className={`${styles.accordionButton} ${styles.accordionButtonCollapse}`}
          id={`toggler${index}`}
        >
          <span className="accordion-header">{type.category}</span>
        </div>

        {extraCollapseState[index] ? (
          <BsChevronCompactUp />
        ) : (
          <BsChevronCompactDown />
        )}
      </div>
      <hr />
      <Collapse isOpen={extraCollapseState[index]}>
        {React.Children.toArray(
          type.items.map((elm) => (
            <FormGroup>
              <Label style={{ marginLeft: "30px" }}>
                <Input
                  type="checkbox"
                  name={`${elm.name} + ${type.category}`}
                  value={`${elm.name} + ${type.category}`}
                  onClick={() => selectionCheck(elm)}
                  checked={
                    extrasSelected.find((e) => e === elm.id) ? true : false
                  }
                />
                <strong>{`${elm.name}`}</strong>
                <p>{`Precio:${elm.retailPrice}€`}</p>
              </Label>
            </FormGroup>
          ))
        )}
      </Collapse>
    </>
  );

  return (
    <>
      {extras !== null || extras !== [] ? (
        <>
          {!dontShowColapse && (
            <div
              className="clearfix mb-3"
              style={{
                padding: ".7rem",
                backgroundColor: "#2C2C2C",
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
                cursor: "pointer",
              }}
              onClick={toggle}
            >
              <span className="tittle ml-4">
                Datos Extras para la Tasación Vehículo
              </span>{" "}
              <i className={`material-icons   ${styles.arrow}`}>
                {" "}
                {isOpen === false ? "expand_more" : "expand_less"}
              </i>
            </div>
          )}
          <Collapse isOpen={dontShowColapse ? true : isOpen}>
            <p>
              {" "}
              {retailPrice
                ? `Valor total del vehículo nuevo con extras: ${price} €`
                : ""}
            </p>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  {React.Children.toArray(
                    extras.map((type, index) => (
                      <>
                        {index % 2 === 0 && renderExtra(type,index)}
                      </>
                    ))
                  )}
                </div>

                <div className="col-md-6 col-sm-12">
                  {React.Children.toArray(
                    extras.map((type, index) => (
                      <>
                        {index % 2 !== 0 && renderExtra(type,index)}
                      </>
                    ))
                  )}
                </div>
              </div>
            </div>
          </Collapse>
          <div className={styles.centerButton}></div>
        </>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
};

export default ExtrasCategory;
