import React from "react";
import { post } from "services/axiosCalls";
import { API } from "services/urlConstants";
import errorCall from "services/errorCall";
import styles from "./index.module.scss";
import { successToast } from "helpers/toastFunctions";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import moment from "moment";
import { MdClose } from "react-icons/md";

const CancelSignComponent = ({ setCancelSign, cancelSign, recall }) => {
  const onConfirm = async () => {
    try {
      const response = await post(API.DELETE_SIGN_DATE, {
        id: cancelSign.document.documentId,
      });
      if (!response.error) {
        recall();
        reset();
        successToast(response.message);
      } else {
        successToast(response.errorMessage);
      }
    } catch (e) {
      errorCall(e);
    }
  };

  const reset = () => {
    setCancelSign({ open: false, document: null });
  };

  return (
    <Modal
      className="modal-xs"
      isOpen={cancelSign?.open}
      toggle={() => reset()}
    >
      <ModalHeader>Firma digital en curso</ModalHeader>
      <ModalBody className="p-4">
        <p>
          {`La firma se inicio con fecha
          ${moment(cancelSign?.document?.sign_start_date).format(
            "DD-MM-yyyy hh:mm"
          )} y esta pendiente`}
        </p>
        <Button
          className="btn-flexicar mt-4 mr-5 right no-print"
          onClick={() => onConfirm()}
        >
          Cancelar proceso de firma
        </Button>
      </ModalBody>
      <div onClick={() => reset()} className={styles.close}>
        <MdClose title="Cerrar" size={30} />
      </div>
    </Modal>
  );
};

export default CancelSignComponent;
