import { useContext, useState } from "react";
import { Button, Input, Label } from "reactstrap";
import Select from "react-select";
import { MdAutorenew } from "react-icons/md";

import styles from "./index.module.scss";
import Mainlayout from "layouts/MainLayout";
import AppointmentStateModal from "./AppointmentStateModal";
import { AppointmentContext } from "./AppointmentContext";
import WeekCalendar from "components/WeekCalendar";
import Collapsible from "components/Collapsible";
import { useMediaQuery } from "hooks/useMediaQuery";

const Appointments = () => {
	const {
		type,
		calendarConfig,
		localesOptions,
		localSelected,
		setLocalSelected,
		resetFilters,
		feria,
		setFeria,
		activateLeadFilters,
		setCurrentFilter,
		eventsFilteredByLeadState
	} = useContext(AppointmentContext);

	const [showModalLeadState, setShowModalLeadState] = useState(false);
	const isDesktop = useMediaQuery("(min-width: 1200px)");

	return (
		<Mainlayout>
			<Mainlayout.Sidebar hide={false}>
				<div>
					<h3>Filtros</h3>
					<div>
						<Label>Concesionarios</Label>
						<Select
							placeholder="Seleccionar..."
							options={localesOptions}
							value={localSelected}
							onChange={(option) => setLocalSelected(option)}
						/>
					</div>
				</div>
				<Button
					className={`${styles.resetButton} btn-flexicar-red`}
					onClick={resetFilters}
				>
					<span>Resetear</span>
					<MdAutorenew size={20} />
				</Button>
				{type === "venta" ? (
					<div className="custom-switch d-flex justify-content-center mt-4">
						<Input
							type="checkbox"
							className="custom-control-input"
							id="reset-password-switch"
							checked={feria}
							onChange={() => setFeria((prev) => !prev)}
						/>
						<Label
							className="custom-control-label"
							for="reset-password-switch"
						>
							Solo feria
						</Label>
					</div>
				) : null}
				<div className="mt-4">
					<Collapsible
						open={isDesktop}
						header={<span>Leyenda</span>}
					>
						<ul className={styles.legend}>
							{calendarConfig.filtersConfig.map((confg) => (
								<li
									key={`filter-${confg.filterLabel}`}
									style={{
										color: activateLeadFilters ? "#2199E9" : "inherit"
									}}
								>
									<div
										className={styles.before}
										style={{
											backgroundColor: confg.color
										}}
									/>
									<span
										style={{
											cursor: activateLeadFilters ? "pointer" : "inherit"
										}}
										onClick={() => {
											if (activateLeadFilters) {
												setCurrentFilter(confg.filter);
												setShowModalLeadState(true);
											}
										}}
									>
										{confg.filterLabel}
										{activateLeadFilters && ` (${eventsFilteredByLeadState[confg.filter]?.length})`}
									</span>
								</li>
							))}
						</ul>
					</Collapsible>
				</div>
			</Mainlayout.Sidebar>

			<Mainlayout.Content contentTitle={`CITAS DE ${type.toUpperCase()}`}>
				<WeekCalendar />
			</Mainlayout.Content>

			<AppointmentStateModal
				showModalLeadState={showModalLeadState}
				setShowModalLeadState={setShowModalLeadState}
			/>
		</Mainlayout>
	);
};

export default Appointments;
