import PropTypes from "prop-types";
import { forwardRef, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

/**
 * @type React.FC<FormattedNumberInputProps>
 */
const FormattedNumberInput = forwardRef(
  (
    {
      value,
      onChange,
      placeholder,
      mask,
      prefix,
      suffix,
      displayType,
      thousandSeparator,
      decimalSeparator,
      decimalScale,
      decimalPow,
      className,
      ...rest
    },
    ref
  ) => {
    const [currency, setCurrency] = useState(value / Math.pow(10, decimalPow));

    useEffect(() => {
      setCurrency(value / Math.pow(10, decimalPow));
    }, [value, decimalPow]);

    return (
      <NumericFormat
        {...rest}
        getInputRef={ref}
        className={className}
        placeholder={placeholder}
        mask={mask}
        value={currency}
        displayType={displayType}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={decimalScale}
        onValueChange={(values) => {
          const decimalValue = values.floatValue;
          setCurrency(decimalValue);
          onChange?.(
            isNaN(decimalValue) ? 0 : decimalValue * Math.pow(10, decimalPow)
          );
        }}
        valueIsNumericString
        prefix={prefix}
        suffix={suffix}
      />
    );
  }
);

const FormattedNumberInputProps = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  mask: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  displayType: PropTypes.oneOf(["input", "text"]),
  thousandSeparator: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  decimalSeparator: PropTypes.string,
  decimalScale: PropTypes.number,
  decimalPow: PropTypes.number,
  className: PropTypes.string,
};
FormattedNumberInput.propTypes = FormattedNumberInputProps;

FormattedNumberInput.defaultProps = {
  placeholder: "0.00 €",
  suffix: " €",
  displayType: "input",
  thousandSeparator: ".",
  decimalSeparator: ",",
  decimalScale: 2,
  decimalPow: 0,
};

FormattedNumberInput.name = "FormattedNumberInput";
export default FormattedNumberInput;
