import React, {
  useEffect,
  useRef,
  Fragment,
  useState,
  useContext,
} from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import { post } from "../../services/axiosCalls";
import { API } from "../../services/urlConstants";
import { errorToast, successToast } from "../../helpers/toastFunctions";
import { useReactToPrint } from "react-to-print";
import styles from "./index.module.scss";
import { UserContext } from "contexts/UserContext";
import { useTranslation } from 'react-i18next';
import RolType from "constants/roles";
import  ModaLSignatureWithoutPhoto  from "components/ModalSignatureWithoutPhoto/ModalSignatureWithoutPhoto";
import Locale from "constants/Locale";

const ModalPrintView = ({
  title,
  open,
  close,
  contractId,
  contractSignStartDate,
  recall,
}) => {
  const { rol } = useContext(UserContext);
  const { i18n } = useTranslation();
  const { t } = useTranslation('myDocumentation');
  const [spinner, setSpinner] = useState(false);
  const [modalNoPhoto, setModalNoPhoto] = useState(false);
  
  const portugueseAccessProtection = i18n.language === Locale.ES

  let ejs = require("ejs");
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (contractId) {
      getTemplate();
    }
    return setSpinner(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getTemplate = async () => {
    const id_contrato = contractId;
    const response = await post("/api/ejs", { id_contrato });
    const html = response[0]?.temp;

    try {
      const data = await post("/api/ejs_data", { id_contrato });

      let data_template = data[0].datos;
      data_template["fecha"] = data[0].fecha;
      data_template["fecha_modificacion"] = data[0].fecha_modificacion;
      data_template["gestoria"] = {
        gestor: data[0].gestor,
        dni: data[0].dni,
        numero: data[0].numero,
        provincia: data[0].provincia,
        direccion: data[0].direccion,
      };
      componentRef.current.innerHTML = ejs.render(html, {
        data: data_template,
      });
    } catch {
      return errorToast(<span>{t("errors.errorMissingContract")} {id_contrato}</span>);
    }
  };

  const getPDF = async (tipo, robusto) => {
    const id_contrato = contractId;

    try {
      setSpinner(true);
      const response = await post(API.DIGITAL_SIGN, {
        id_contrato,
        tipo,
        robusto,
      });
      if (response.error && response.errorMessage) {
        setSpinner(false);
        return errorToast(<span>{response.errorMessage}</span>);
      }

      tipo === 1
        ? successToast(
            <span>Se ha procesado la firma, revise su telefono movil</span>
          )
        : successToast(<span>Se ha procesado la firma, revise su email</span>);
      recall();
      close();
    } catch {
      setSpinner(false);
      return errorToast(<span>{t("errors.errorSignContract")}</span>);
    }
  };

  return (
    <>
      <Modal className={"modal-xl"} isOpen={open} toggle={close}>
        <ModalHeader>{t('contractPdf.documentFrom')} {t(title)}</ModalHeader>
        <ModalBody className="p-4">
          <div>
            {" "}
            {!contractSignStartDate && !spinner && (
              <div className={styles.space}>
                <Button
                  className="btn-flexicar mt-4 mr-5 right no-print"
                  onClick={handlePrint}
                >
                  {t('contractPdf.printContract')}
                </Button>
                {portugueseAccessProtection && rol &&
                  rol?.some(
                    (e) =>
                      e === RolType.TRADING_AGENT ||
                      e === RolType.TRADING_MANAGER ||
                      e === RolType.GENERAL_ADMIN ||
                      e === RolType.ADMINISTRATION ||
                      e === RolType.PURCHASING_AGENT
                  ) && (
                   <Fragment>
                      <Button
                        className="btn-flexicar mt-4 mr-5 right no-print"
                        onClick={() => getPDF(1, 2)}
                      >
                       {t('contractPdf.signPhoto')}
                      </Button>
                      <Button
                        className="btn-flexicar mt-4 mr-5 right no-print"
                        onClick={() => setModalNoPhoto(true)} 
                      >
                       {t('contractPdf.signNoPhoto')}
                      </Button>
                    </Fragment>
                  )}
              </div>
            )}
            {spinner && (
              <div>
                <Spinner type="grow" className="flexicarColor right" />
              </div>
            )}
            <br />
            <div ref={componentRef} className="m-5" id="template"></div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => close()}>
          {t('contractPdf.close')}
          </Button>
        </ModalFooter>
      </Modal>
      <ModaLSignatureWithoutPhoto
        open={modalNoPhoto}
        setModal={setModalNoPhoto}
        getPDF={getPDF}
      />
    </>
  );
};

export default ModalPrintView;
