import React, { useState, useEffect, useContext, useCallback } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Filter from "components/Filter";
import { post } from "../../services/axiosCalls";
import { API } from "../../services/urlConstants";
import { errorToast, successToast } from "../../helpers/toastFunctions";
import { UserContext } from "contexts/UserContext";
import { useTranslation } from "react-i18next";


const ModalChangeState = ({status, setModal, id_lead, id_cliente}) => {
    const { t } = useTranslation("modalChangeState");
    const [lead, setLead] = useState(null);
    const { user } = useContext(UserContext);

    const getLeadData = useCallback(
    async  (id) => {
        const response = await post(API.GET_LEAD_VENTA, {
          agent: user,
          id,
        });
        setLead(response[0]);
      },
      [user],
    )
    

    // const getLeadData = async (id) => {
    //     const response = await post(API.GET_LEAD_VENTA, {
    //       agent: user,
    //       id,
    //     });
    //     setLead(response[0]);
    //   };
    

    useEffect(() => {
        getLeadData(id_lead);
      }, [getLeadData, id_lead]);
   


  const getData = async (f, value) => {
 
    if (value && value[0] && lead && value[0].value != lead.id_estado_lead) {
      const response = await post(API.UPDATE_LEAD_VENTA_STATUS, {
        label: value[0].label,
        value: value[0].value,
        lead: id_lead,
        client: id_cliente,
        user
      });
      if (response.changedRows && response.changedRows === 1) {
        setLead({
          ...lead,
          id_estado_lead: value[0].value,
          nombre_estado_lead: value[0].label,
        });
        successToast("Estado actualizado correctamente");
        setModal(false)
      } else {
        errorToast(<span>{t("errors.errorChangeState")}</span>);
      }
    }
  };


  

    return (
        <Modal
            className="modal-lg"
            centered
            isOpen={status}
            toggle={() => setModal()}
        >
            <ModalHeader>Acciones Sobre el Lead</ModalHeader>
            <ModalBody className="p-4">
            <Filter
            key={"TiposResultadosLeeds"}
            setter={"setFilterEstados"}
            multi={false}
            sendData={getData}
            parentValues={
              lead
                ? [
                    {
                      label: lead.nombre_estado_lead,
                      value: lead.id_estado_lead,
                    },
                  ]
                : ""
            }
            name={lead?.nombre_estado_lead}
            query={`estado_lead_venta_acciones`}
          />
                </ModalBody>
            <ModalFooter>

            </ModalFooter>
        </Modal>

    );

}

export default ModalChangeState;
