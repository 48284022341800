import React from "react";
import Select, { components } from "react-select";
import { MdCheck } from "react-icons/md";

import OverflowWrapper from "./OverflowWrapper";
import customStyles from "./styles";

const MultiValueContainer = (props) => {
  return (
    <div data-targetid={props.data.value} {...props.innerProps}>
      {props.children}
    </div>
  );
};

const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      {props.hasValue ? (
        <OverflowWrapper>{children}</OverflowWrapper>
      ) : (
        children
      )}
    </components.ValueContainer>
  );
};

const Option = ({ label, isSelected, ...rest }) => (
  <components.Option {...rest}>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {label}
      {isSelected && <MdCheck size={24} color="#D34600" />}
    </div>
  </components.Option>
);

const MultiSelect = ({ options, value, onChange, placeholder, ...rest }) => (
  <Select
    {...rest}
    styles={customStyles()}
    isMulti
    isSearchable
    isClearable
    options={options}
    value={value}
    onChange={onChange}
    menuPlacement="auto"
    closeMenuOnSelect={false}
    hideSelectedOptions={false}
    maxMenuHeight={300}
    placeholder={placeholder}
    components={{
      ValueContainer,
      MultiValueContainer,
      Option,
    }}
  />
);

export default MultiSelect;
