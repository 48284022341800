import React, { useState } from "react";

import { Input, Label, Row, Col, Button } from "reactstrap";

import styles from "./index.module.scss";

import moment from "moment";

import classes from "./index.module.scss";
import DatePicker from "react-datepicker";

import { FiPaperclip } from "react-icons/fi";
import { useTranslation } from "react-i18next";

import UploadNoCallComponent from "components/UploadNoCallComponent";
import AdditionalDocumentType from "constants/AdditionalDocumentType";
import {
  deleteDocument,
  getDocument,
  postDocument,
} from "services/Vehicle/vehicle.service";
import { errorToast, successToast } from "helpers/toastFunctions";
import ModalWarning from "components/ModalWarning";
import AdditionalInfoTable from "components/AdditionalInfoTable";


const AdditionalInfo = ({
  errors,
  handleAdditionalInfo,
  handleDocuments,
  handleDeleteDocument,
  handleCheckCambeltReplace,
  documents,
  additionalInfo,
  saveAdditionalInfo,
  id,
  type,
}) => {
  const { t } = useTranslation('vehicleValidation');
  const [showAddFileModal, setShowAddFileModal] = useState(false);
  const [documentType, setDocumentType] = useState(0);
  const [warningAlert, setWarningAlert] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [viewingDocument, setViewingDocument] = useState(false);
  const [spinner, setSpinner]=useState(false)
  const allowedFormats = [".pdf", ".jpg", ".jpeg", ".doc", ".docx"];
  const maxLengthTitle = 35;
  const handleDocumentType = (e, type) => {
    setDocumentType(e.target.value);
  };

  const handleViewDocument = async (documentId) => {
    setViewingDocument(documentId);

    try {
      const documentUrl = await getDocument(id, type, documentId);

      if (documentUrl) {
        window.open(documentUrl, "_blank");
      }
    } catch (error) {
      console.error("Error al obtener el documento:", error);
    } finally {
      setViewingDocument(null);
    }
  };
  const saveDocument = async (file) => {
    setSpinner(true)
    const formData = new FormData();

    formData.append("document", file.object);
    formData.append("fileTitle", file.name);
    formData.append("typeDocumentId", documentType);
    try {
      const { data } = await postDocument(id, type, formData);
      successToast("Información Adicional guardada correctamente");
      handleDocuments(data);
    } catch (error) {
      errorToast("Error al guardar Información Adicional");
      return { error };
    }
    setSpinner(false)
  };
  const handleDocumentDelete = (documentId) => {
    setDocumentToDelete(documentId);
    setWarningAlert(true);
  };
  const deleteDocumentInfo = async () => {
    try {
      const response = await deleteDocument(id, type, documentToDelete);

      handleDeleteDocument(documentToDelete);
      successToast("Documento borrado correctamente");
      setDocumentToDelete(null);
      setWarningAlert(false);
    } catch (error) {
      errorToast("Error al borrar el documento");
      return { error };
    }
  };
  const cancelDelete = () => {
    setWarningAlert(false);
    setDocumentToDelete(false);
  };

  return (
    <>
      <Row className={styles.centerRow}>
        <Col xs="12" sm="12">
          <h4 className={classes.bold}>{t("additionalFields")}</h4>
        </Col>
        <Col xs="12" sm="6">
          <Label className={"m-0"}>{t("documentType")}</Label>
          <div className={styles.filterAdjust}>
            <Input
              placeholder={t("documentType")}
              className="form-control ds-input mt-1 pointer"
              type="select"
              invalid={errors.document === true}
              value={documentType}
              required
              onChange={(e) => {
                handleDocumentType(e);
              }}
            >
              <option value={0} disabled>
              {t("documentType")}
              </option>
              <option value={1}>{t("Carfax")}</option>
              <option value={2}>{t("Manual")}</option>
              <option value={3}>{t("TechnicalSheet")}</option>
              <option value={4}>{t("expertiseDocuments")}</option>
              <option value={5}>{t("others")} </option>
            </Input>
          </div>
        </Col>
        <Col xs="12" sm="6">
          {documentType ? (
            <>
              <FiPaperclip
                size={26}
                onClick={() => setShowAddFileModal(true)}
              />
              <span style={{ color: "#D34600" }}>{t("uploadData")}</span>
            </>
          ) : null}
        </Col>
        <Col xs="12" sm="12">
          <h4 className={classes.bold}>{t("additionalInformation")}</h4>
        </Col>
        {documents.length > 0 && (
          <AdditionalInfoTable
            documents={documents}
            AdditionalDocumentType={AdditionalDocumentType}
            viewingDocument={viewingDocument}
            handleViewDocument={handleViewDocument}
            handleDocumentDelete={handleDocumentDelete}
            spinner={spinner}
          />
        )}

        <Col xs="12" sm="6">
          <div style={{ display: "flex" }}>
            <DatePicker
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              className={`${styles.date}`}
              selected={additionalInfo.lastInspectionDate}
              onChange={(element) =>
                handleAdditionalInfo(
                  { target: { value: element } },
                  "lastInspectionDate"
                )
              }
              locale="es"
              customInput={
                additionalInfo.lastInspectionDate ? (
                  <Label>
                    {t("lastDateRevision")}
                    <Input
                      value={moment(additionalInfo.lastInspectionDate).format(
                        "DD-MM-YYYY"
                      )}
                    />
                  </Label>
                ) : (
                  <Label>
                    {t("lastDateRevision")}
                    <Input value={t("selectDate")} />
                  </Label>
                )
              }
            />
          </div>
        </Col>

        <Col xs="12" sm="6">
          <Label className={"m-0"}>{t("lastKmRevision")}</Label>
          <Input
            placeholder={t("revisionKm")}
            className="form-control ds-input mt-1"
            type="number"
            value={additionalInfo.inspectionKm}
            onChange={(e) => {
              handleAdditionalInfo(e, "inspectionKm");
            }}
          />
        </Col>

        <Col xs="12" sm="6">
          <Label style={{ cursor: "pointer", marginLeft: "30px" }} check>
            <Input
              type="checkbox"
              onClick={handleCheckCambeltReplace}
              checked={additionalInfo?.cambeltReplace}
            />
            {t("timingBeltChange")}
          </Label>
        </Col>
        {additionalInfo?.cambeltReplace && (
          <Col xs="12" sm="6">
            <Label className={"m-0"}>{t("kmChangeBelt")}</Label>
            <Input
              placeholder={t("kmBelt")}
              className="form-control ds-input mt-1"
              type="number"
              value={additionalInfo.cambeltReplaceKm}
              onChange={(e) => {
                handleAdditionalInfo(e, "cambeltReplaceKm");
              }}
            />
          </Col>
        )}
        <Col xs="12" sm="12">
          <h6 className="mt-4">{t("comments")}: </h6>
          <textarea
            style={{ width: "100%", borderColor: "#ced4da", padding: "10px" }}
            value={additionalInfo.comment}
            onChange={(e) => handleAdditionalInfo(e, "comment")}
            maxlength="300"
          />
          <div className={styles.characters}>
            <small>
              {additionalInfo.comment ? additionalInfo.comment.length : "0"}
              /300 carácteres
            </small>
          </div>
        </Col>
        <Col xs="12" sm="12" className={`${styles.buttonRigth}`}>
          <Button
            className={`btn-flexicar`}
            onClick={() => saveAdditionalInfo()}
          >
            GUARDAR
          </Button>
        </Col>
      </Row>
      <UploadNoCallComponent
        onFile={saveDocument}
        show={showAddFileModal}
        setShow={setShowAddFileModal}
        allowedFormats={allowedFormats}
        maxLengthTitle={maxLengthTitle}
      ></UploadNoCallComponent>
      <ModalWarning
        okButton={deleteDocumentInfo}
        cancelButton={cancelDelete}
        open={warningAlert}
      ></ModalWarning>
    </>
  );
};

export default AdditionalInfo;
