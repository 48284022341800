import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";

const DatepickerFilters = ({
  date,
  sendDate
}) => {

  const years = Array.from(Array(new Date().getFullYear() + 2).keys()).slice(1900);
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];


  return (
    <div>
     <DatePicker
        dateFormat="dd/MM/yyyy"
        placeholderText="Seleccionar fecha"
        locale="es"
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>

          <select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => {
              changeYear(value)
            }
            }
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={date.getMonth()}
            onChange={(e) => {
                changeMonth(e.target.value)
              }
            }
          >
            {months.map((option, index) => (
              <option key={index} value={index}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>

        </div>
      )}
      selected={date}
      onChange={(date) => 
        sendDate(date)
      }
    />
    </div>
  );
};

export default DatepickerFilters;
