import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  Label,
  Input,
  Row,
  FormGroup,
} from "reactstrap";
import styles from "./index.module.scss";

const ModalPreview = ({imagePrincipal, modalPreview, togglePreview, vehiculo, setImpresion}) => {


  return (
    <>
      <div>
        <Modal isOpen={modalPreview} size="xl">
          <ModalHeader>Resumen de Vehículo</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="6" md="6">
                <div width="250px">
                  <img
                    src={imagePrincipal?.url}
                    width={"100%"}
                    alt="MainPicture"
                  />
                </div>
                <div style={{ position: "relative" }}>
                  {" "}
                  {vehiculo?.pvp_financiado &&
                  vehiculo?.precio_oferta_particulares ? (
                    <p className={styles.tachado}>
                      {" "}
                      {vehiculo?.pvp_financiado}€{" "}
                    </p>
                  ) : (
                    ""
                  )}
                  {vehiculo?.precio_oferta_particulares ? (
                    <p className={styles.oferta}>
                      <strong> {vehiculo?.precio_oferta_particulares}€ </strong>
                    </p>
                  ) : (
                    ""
                  )}
                  {vehiculo?.pvp_contado ? (
                    <p>
                      <strong>
                        {" "}
                        Precio al contado: {vehiculo?.pvp_contado}€{" "}
                      </strong>
                    </p>
                  ) : (
                    ""
                  )}
                  {vehiculo?.pvp_financiado &&
                  !vehiculo?.precio_oferta_particulares ? (
                    <p>
                      <strong>
                        {" "}
                        Precio financiado: {vehiculo?.pvp_financiado}€{" "}
                      </strong>
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col md="6">
                <h3>
                  {vehiculo?.version[0]?.label
                    ? `${vehiculo?.marca_vehiculo[0]?.label} ${vehiculo?.modelo[0]?.label} ${vehiculo?.version[0]?.label}`
                    : "-"}
                </h3>
                <Row>
                  <Col md="4">
                    <p>Año</p>
                    <p>
                      <strong>
                        {vehiculo?.matriculacion[0]?.label
                          ? `${vehiculo?.matriculacion[0]?.label}`
                          : "-"}
                      </strong>
                    </p>
                    <p>Puertas / Plazas</p>
                    <p>
                      <strong>
                        {vehiculo?.puertas && vehiculo?.asientos
                          ? `${vehiculo?.puertas} / ${vehiculo?.asientos}`
                          : "-"}
                      </strong>
                    </p>
                    <p>Cambio</p>
                    <p>
                      <strong>
                        {vehiculo?.ccambios[0]?.label
                          ? `${vehiculo?.ccambios[0]?.label}`
                          : "-"}
                      </strong>
                    </p>
                  </Col>
                  <Col md="4">
                    <p>Kilómetros</p>
                    <p>
                      <strong>{vehiculo?.km ? `${vehiculo?.km}` : "-"}</strong>
                    </p>
                    <p>Motor</p>
                    <p>
                      <strong>
                        {vehiculo?.cilindrada && vehiculo?.potencia
                          ? `${vehiculo?.cilindrada} cc / ${vehiculo?.potencia} C.V.`
                          : "-"}
                      </strong>
                    </p>
                    <p>Consumo</p>
                    <p>
                      <strong>
                        {vehiculo?.consumo_combi
                          ? `${vehiculo?.consumo_combi}l/100`
                          : "-"}
                      </strong>
                    </p>
                  </Col>
                  <Col md="4">
                    <p>Combustible</p>
                    <p>
                      <strong>
                        {vehiculo?.combustible[0]?.label
                          ? `${vehiculo?.combustible[0]?.label}`
                          : "-"}
                      </strong>
                    </p>
                    <p>Color</p>
                    <p>
                      <strong>
                        {vehiculo?.color[0]?.label
                          ? `${vehiculo?.color[0]?.label}`
                          : "-"}
                      </strong>
                    </p>
                    <p>I.V.A. Deducible</p>
                    <p>
                      <strong>{vehiculo.iva_deducible ? "SI" : "NO"}</strong>
                    </p>
                  </Col>
                  <Col md="12" className={styles.local}>
                    <svg
                      width="14"
                      height="14"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      color="currentColor"
                      class="jss1387"
                      aria-hidden="true"
                    >
                      <path
                        d="M20 10c0 4.418-8 12-8 12s-8-7.582-8-12a8 8 0 1116 0z"
                        stroke="currentColor"
                      ></path>
                      <path
                        d="M12 11a1 1 0 100-2 1 1 0 000 2z"
                        fill="currentColor"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    {vehiculo?.local[0] ? `${vehiculo?.local[0]?.label}` : "-"}
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => { setImpresion(true) }} >
              Publicar
            </Button>
            {""}
            <Button color="secondary" onClick={togglePreview}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        
      </div>
    </>
  );
};

export default ModalPreview;
