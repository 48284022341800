import { Button } from "reactstrap";
import styles from "./index.module.scss";
import ModalCitar from "views/NewLead/ModalCitar/ModalCitar";
import Checkbox from "components/Checkbox/";
import Extras from "views/NewLead/ExtrasCategory/ExtrasCategory";
import Filter from "components/Filter";
import { errorToast } from 'helpers/toastFunctions';
import { post, postFile } from "../../../services/axiosCalls";
import { API } from "../../../services/urlConstants";
import React, { useState, useEffect, useContext, useMemo } from "react";
import EstadosVehiculo from "views/NewLead/EstadosVehiculos/EstadosVehiculos";
import DatosVehiculosPortales from "components/DatosVehiculosPortales";
import DatosVehiculoClasico from "components/DatosVehiculosClasico";
import NewEmail from "views/NewLead/ModalEnviarMail/ModalSendEmail";
import EstadosLead from "views/NewLead/TableEstadosLeeds/LeadsStateTable";
import Conversaciones from "views/NewLead/ModalConversacionesLeeds/ModalConversacionLead";
import getIdTipo from "../../../helpers/user/getIdTipo";
import ModalBack from "views/NewLead/ModalBack/ModalBack";
import { RiSave3Fill } from "react-icons/ri";
import { BsPrinterFill } from "react-icons/bs";
import { UserContext } from "contexts/UserContext";
import { MdNoteAdd } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import Locale from "constants/Locale";
import RolType from "constants/roles";
import LEAD_STATUS from "constants/LeadStatus";
import UploadCarousel from "components/UploadCarousel";
import Select from "react-select";
import NoAutoCompleteInputSelect from "components/NoAutoCompleteInputSelect";

const FormLead = ({
  launchModal,
  handleValues,
  getDataAdd,
  getData_CheckBox,
  createLead,
  state,
  setState,
  lead_id,
  vehiculo,
  setVehiculo,
  vehiculoOld,
  estado,
  sendData,
  sendEstados,
  setModalPrintPercha,
  price,
  setRetailPrice,
  retailPrice,
  setPrice,
  mercedes,
  updateEstado,
  getDataLocal,
  localActual
}) => {
  const [userType, setUserType] = useState();
  const { user } = useContext(UserContext);
  const { rol } = useContext(UserContext);
  const { local } = useContext(UserContext);
  const loadUserType = async () => {
    const idType = await getIdTipo(user);

    return setUserType(idType);
  };
  const { i18n } = useTranslation();
  const { t } = useTranslation("fichaLeadsCompra");

  const [provincias, setProvincias] = useState([]);
  const [leadStates, setLeadStates] = useState([]);
  const [leadAgents, setLeadAgents] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [leadContactForms, setLeadContactForms] = useState([]);
  const [commercialBrands, setCommercialBrands] = useState([]);
  const [waitForBucketUpload, setWaitForBucketUpload] = useState(false);

  
  const noImg = useMemo(() => ({
    id: -1,
    url: `/locales/${i18n.language}/CarouselNoImg.svg`,
  }), [i18n]);
  const [images, setImages] = useState([noImg]);


  useEffect(() => {
    (async () => {
      const idType = await getIdTipo(user);
      setUserType(idType);
    })();

    (async () => {
      if (lead_id) {
        const response = await post(API.GET_IMAGES, { lead_id });
        if (response && response.records.length > 0)
          setImages(
            response.records.map((record) => ({
              id: record.id,
              url: record.publicUrl,
            }))
          );
      }
    })();
  }, [lead_id, user]);

  useEffect(() => {
    const makePost = (query, data, setter) => {
      const dataCall = {
        data_query: query,
        data_call: data ? data : null,
      };
      post(API.FILTROS, { dataCall })
        .then((res) =>
          setter(
            res.map((x) => ({
              label: x.nombre,
              value: x.id,
            }))
          )
        )
        .catch((err) => errorToast(err));
    };

    if (user && userType) {
      makePost("provincias", undefined, setProvincias);
      makePost("estado_lead_compra", undefined, setLeadStates);
      if (state.edit_mode)
        makePost("agente_lead_compra", [userType, user, userType], setLeadAgents);
      makePost("origen_lead_compra", undefined, setLeadSources);
      makePost("contacto", undefined, setLeadContactForms);
      makePost("marcas_comerciales", user, setCommercialBrands);
    }
  }, [state.edit_mode, user, userType]);

  useEffect(() => {
    if (retailPrice !== "" || retailPrice !== null) {
      setRetailPrice(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiculo]);

  const validatePhone = (str) => {
    if (i18n.language === Locale.ES && /^[5-9]{1}[0-9]{8}$/g.test(str)) {
     return true;
   }
    else if (i18n.language === Locale.PT && /^[9287]{1}[0-9]{8}$/g.test(str)) {
     return true;
   }
   else{
      errorToast(<span>{t("errors.errorPhone")}</span>)
     return false;
   }
 }

 useEffect(() => {
   if (images.length === 0) setImages([noImg]);
 }, [images, noImg]);


 const updateImgs = (newImgs) => {
   images[0].id === -1
     ? setImages(newImgs)
     : setImages((prev) => [...prev, ...newImgs]);
 };

  const validatePrice = (price) => {
    if (RolType.CALL_CENTER) return true;

    if (!price === "" || price > 0) {
      return true;
    } else {
      errorToast(`${t('lookedPrice')} ${t('errors.errorNumMandatory')}`);
      return false;
    }
  };

  const validation = async () => {
    if(validatePhone(state.checkLead.telefono1) &&
       validatePrice(state.checkLead?.buscado)){
      state.edit_mode
        ? await createLead()
        : await createLead(handleUploadBucketImages);
      }
  };

 const handleImagesOnMemory = (files) => {  
   const newImgs = files.map((file) => ({
     id: file.name,
     url: URL.createObjectURL(file),
     fileObject: file
   }));
   updateImgs(newImgs);
 };

 const deleteImageOnMemory = (imageId) =>
   setImages((prevImages) => prevImages.filter((x) => x.id !== imageId));

 const deleteAllImagesOnMemory = () => setImages([]);

 const handleUploadBucketImages = async (files, leadId) => {
   const formData = new FormData();
   formData.append("relativePath", leadId ?? lead_id);
   formData.append("token_name", "tasaciones_images");
   if (files !== undefined)
     files.map((file) => formData.append(file.name, file));
   else if (files === undefined && images[0].id !== -1)
     images.map(({ fileObject }) =>
       formData.append(fileObject.name, fileObject)
     );

   try {
     setWaitForBucketUpload(true);
     const uploadFilesMultiResp = await postFile(
       API.UPLOAD_FILES_MULTI,
       formData
     );
     if (uploadFilesMultiResp && uploadFilesMultiResp.res.length > 0) {
       try {
         const saveFilesDataResp = await post(API.SAVE_FILES_DATA, {
           id_lead: leadId ?? lead_id,
           data: uploadFilesMultiResp.res,
         });

         if (!saveFilesDataResp.error)
           updateImgs(
             uploadFilesMultiResp.res.map((file) => ({
               id: file.signUrlId,
               url: file.publicUrl,
             }))
           );
       } catch (e) {
         errorToast(<span>{t("errors.errorImage")}</span>);
       } finally {
         setWaitForBucketUpload(false);
       }
     }
   } catch (e) {
     errorToast(<span>{t("errors.errorUploadImage")}</span>);
   }
 };

 const deleteBucketImage = async (imageId) => {
   await post(API.DELETE_IMAGES, { id: imageId });
   setImages((prevImgs) => prevImgs.filter((x) => x.id !== imageId));
 };

 const deleteAllBucketImagesForLead = async () => {
   await post(API.DELETE_ALL_IMAGES, { lead_id });
   setImages([]);
 };


const checkPendingAppraisal = (filter, value) => {
  const preserveStatus= {
    ...state,
    checkLead: {
      ...state.checkLead,
      estado: state.checkLead.estado ? [...state.checkLead.estado] : []
    }
  }

  if (!localActual) {
    setState(preserveStatus)
    return errorToast(<span>{t("errors.errorLocal")}</span>);

  }
  else if (images[0].id === -1) {
    setState(preserveStatus)
    return errorToast(<span>{t("errors.errorPhotos")}</span>);

  }

  else {
    return updateEstado(filter, value)
  }
}
 
  const sendAvailableStatus = (filter, value) => {
    
    if (value[0]?.value === LEAD_STATUS.pendiente_tasacion_central && state.checkLead.estado[0].value !== LEAD_STATUS.pendiente_tasacion_central ) {
      checkPendingAppraisal(filter, value)
    }
    else {
      updateEstado(filter, value)
    }
  }

  return (
    <>
      {state.add_mode || state.edit_mode ? (
        <>
          <div>
            <div className={mercedes ? `${styles.mercedes}` : null}>
              <div
                className="clearfix mb-3"
                style={{
                  padding: ".7rem",
                  backgroundColor: "#2C2C2C",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1rem",
                  justifyContent: "space-between",
                }}
              >
                <span className="tittle ml-4 mr-auto p-2">
                  {t('contactInfo')}
                </span>
                {state.edit_mode ? (
                  <>
                    {i18n.language === Locale.ES ?
                      <NewEmail
                        nombre={state.checkLead.nombre}
                        email={state.checkLead.email}
                      />: null}
                    <EstadosLead lead_id={lead_id} user={user} />
                    <Conversaciones lead_id={lead_id} user={user} />{" "}
                  </>
                ) : null}
              </div>

              <div className="row">
                <div className="col-md-4 col-sm-12 ">
                  <div className={styles.floatingLabel}>
                    <input
                      id="Nombre"
                      placeholder={t('contactName')}
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      type="text"
                      value={state.checkLead?.nombre}
                      onChange={(e) => handleValues(e, "nombre", "checkLead")}
                    />
                    <label for="Nombre">{t('contactName')}:</label>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className={styles.floatingLabel}>
                    <input
                      id="Apellido1"
                      placeholder={t('contactFirstName')}
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      type="text"
                      value={state.checkLead?.apellido1}
                      onChange={(e) =>
                        handleValues(e, "apellido1", "checkLead")
                      }
                    />
                    <label for="Apellido1">{t('contactFirstName')}:</label>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className={styles.floatingLabel}>
                    <input
                      placeholder={t('contactSecondName')}
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      type="text"
                      value={state.checkLead?.apellido2}
                      onChange={(e) =>
                        handleValues(e, "apellido2", "checkLead")
                      }
                    />
                    <label for="Apellido1">{t('contactSecondName')}:</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className={styles.floatingLabel}>
                    <input
                      placeholder={t('phone')}
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      type="text"
                      disabled={
                        state.add_mode && !state?.search_matricula
                          ? true
                          : false
                      }
                      value={state.checkLead?.telefono1}
                      onChange={(e) =>
                        handleValues(e, "telefono1", "checkLead")
                      }
                    />
                    <label for="telefono">{t('phone')}:</label>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className={styles.floatingLabel}>
                    <input
                      placeholder={t('phone2')}
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      type="text"
                      value={state.checkLead?.telefono2}
                      onChange={(e) =>{
                        handleValues(e, "telefono2", "checkLead")
                      }

                      }
                    />
                    <label for="telefono">{t('phone2')}:</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className={styles.floatingLabel}>
                    <input
                      placeholder={t('email')}
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      value={state.checkLead?.email}
                      onChange={(e) => handleValues(e, "email", "checkLead")}
                    />
                    <label for="telefono">{t('email')}:</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className={`col-md-3 col-sm-12 ${styles.floatingLabel}`}>
                  <label
                    className={
                      state.checkLead?.provincia
                        ? styles.select
                        : styles.notSelect
                    }
                  >
                    {t("city")}:
                  </label>
                  <div className={styles.filter}>
                    <Select
                        options={provincias}
                        placeholder={t("city")}
                        onChange={(newValue, action) =>
                          getDataAdd("provincia", [newValue])
                        }
                        defaultValue={state.checkLead?.provincia}
                        components={{ Input: NoAutoCompleteInputSelect }}
                    />
                  </div>
                </div>
                <div className="col-md-9 col-sm-12">
                  <div className={styles.floatingLabel}>
                    <input
                      placeholder={"Municipio"}
                      type="text"
                      className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                      value={state.checkLead?.municipio}
                      onChange={(e) =>
                        handleValues(e, "municipio", "checkLead")
                      }
                    />

                    <label for="telefono">Municipio:</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <Checkbox
                  key={"Condiciones"}
                  name={t('privatePolicy')}
                  status={"checked"}
                  onChange={() =>
                    getData_CheckBox(
                      !state.checkLead.condiciones,
                      "condiciones"
                    )
                  }
                />
                <br />
                <Checkbox
                  key={"Comercial"}
                  name={t('communicationPolicy')}
                  status={state.checkLead.comercial}
                  onChange={() =>
                    getData_CheckBox(!state.checkLead.comercial, "comercial")
                  }
                />
              </div>
            </div>
            <div
              className="clearfix mb-3"
              style={{
                padding: ".7rem",
                backgroundColor: "#2C2C2C",
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <span className="tittle ml-4">{t('leadData')}</span>
            </div>
            <div className="row">
              <div
                className={
                  state.edit_mode && !mercedes
                    ? `col-md-8 col-sm-12 `
                    : "col-md-12 col-sm-12"
                }
              >
                <div>
                  <div className="row" style={{ marginTop: ".25rem" }}>
                    <div
                      className={`col-md-12 col-sm-12 ${styles.floatingLabel}`}
                    >
                      <div className={styles.filter}>
                        <label
                          className={
                            state.checkLead?.estado
                              ? styles.select
                              : styles.notSelect
                          }
                        >
                          {t("leadState")}
                        </label>
                        <Filter
                          id="estado"
                          key={"Estado del Lead"}
                          setter={"estado"}
                          sendData={(name, value) => {
                            sendAvailableStatus(name, value);
                          }}                          
                          parentValues={state.checkLead?.estado}
                          name={t('leadState')}
                          query={`estado_lead_compra`}
                          className={` ${styles.outline}`}
                        />
                      </div>
                    </div>
                    {state.edit_mode && userType ? (
                      <div
                        className={`col-md-12 col-sm-12 ${styles.floatingLabel}`}
                      >
                        <label
                          className={
                            state.checkLead?.agente
                              ? styles.select
                              : styles.notSelect
                          }
                        >
                          Agente:
                        </label>
                        <div className={styles.filter}>
                        <Select
                            options={leadAgents}
                            placeholder="Agente"
                            onChange={(newValue, action) =>
                              getDataAdd("agente", [newValue])
                            }
                            defaultValue={state.checkLead?.agente}
                            isDisabled={mercedes ? true : false}
                            components={{ Input: NoAutoCompleteInputSelect }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="row" style={{ marginTop: ".25rem" }}>
                    <div
                      className={`col-md-6 col-sm-12 ${styles.floatingLabel}`}
                    >
                      <label
                        className={
                          state.checkLead?.origen
                            ? styles.select
                            : styles.notSelect
                        }
                      >
                        {t("leadSource")}
                      </label>
                      <div className={styles.filter}>
                        <Select
                            className={
                              state.edit_mode &&
                              state.checkLead?.forma_contacto &&
                              state.checkLead?.forma_contacto[0].value === 3
                                ? ` ${styles.disabled}`
                                : ` ${styles.outlineSelect}`
                            }
                            options={leadSources}
                            placeholder={t('leadSource')}
                            onChange={(newValue, action) =>
                              getDataAdd("origen", [newValue])
                            }
                            defaultValue={state.checkLead?.origen}
                            isDisabled={
                              state.edit_mode &&
                              state.checkLead?.forma_contacto &&
                              state.checkLead?.forma_contacto[0].value === 3
                                ? true
                                : false
                            }
                            components={{ Input: NoAutoCompleteInputSelect }}
                          />
                      </div>
                    </div>
                    <div
                      className={`col-md-6 col-sm-12 ${styles.floatingLabel}`}
                    >
                      <label
                        className={
                          state.checkLead?.forma_contacto
                            ? styles.select
                            : styles.notSelect
                        }
                      >
                        {t("leadContactForm")}:
                      </label>
                      <div className={styles.filter}>
                        <Select
                          className={
                            state.edit_mode &&
                            state.checkLead?.forma_contacto &&
                            state.checkLead?.forma_contacto[0].value === 3
                              ? ` ${styles.disabled}`
                              : ` ${styles.outlineSelect}`
                          }
                          options={leadContactForms}
                          placeholder={t("leadContactForm")}
                          onChange={(newValue, action) =>
                            getDataAdd("forma_contacto", [newValue])
                          }
                          defaultValue={state.checkLead?.forma_contacto}
                          isDisabled={
                            (state.edit_mode &&
                              state.checkLead?.forma_contacto &&
                              state.checkLead?.forma_contacto[0]?.value ===
                                3) ||
                            mercedes
                              ? true
                              : false
                          }
                          components={{ Input: NoAutoCompleteInputSelect }}
                        />
                      </div>
                    </div>
                    <div
                      className={`col-md-6 col-sm-12 ${styles.floatingLabel}`}
                    >
                      <label
                        className={
                          state.checkLead?.local_actual
                            ? styles.select
                            : styles.notSelect
                        }
                      >
                        {t("local")}:
                      </label>
                      <div className={styles.filter}>
                        <Filter
                          key={"LocalActual"}
                          setter={"local_actual"}
                          sendData={getDataLocal}
                          parentValues={localActual}
                          name={t('local')}
                          query={`locales`}
                          data={[local]}
                          className={styles.outline}
                        />{" "}
                      </div>
                    </div>
                    <div
                      className={`col-md-6 col-sm-12 ${styles.floatingLabel}`}
                    >
                      <label
                        className={
                          state.checkLead?.marca_comercial
                            ? styles.select
                            : styles.notSelect
                        }
                      >
                        {t("commercialBrand")}:
                      </label>
                      <div className={styles.filter}>
                        <Select
                          className={
                            state.edit_mode &&
                            state.checkLead?.forma_contacto &&
                            state.checkLead?.forma_contacto[0].value === 3
                              ? ` ${styles.disabled}`
                              : ` ${styles.outlineSelect}`
                          }
                          options={commercialBrands}
                          placeholder={t("commercialBrand")}
                          onChange={(newValue, action) =>
                            getDataAdd("marca_comercial", [newValue])
                          }
                          defaultValue={state.checkLead?.marca_comercial}
                          isDisabled={
                            state.edit_mode &&
                            state.checkLead?.forma_contacto &&
                            state.checkLead?.forma_contacto[0]?.value === 3
                          }
                          components={{ Input: NoAutoCompleteInputSelect }}
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                {state.edit_mode && !mercedes ? (
                  <ModalCitar
                    launchModal={launchModal}
                    getDataAdd={getDataAdd}
                    handleValues={handleValues}
                    state={state}
                    setState={setState}
                    user={user}
                    className={`btn btn-light btn-outline-dark ${styles.bgroup} btn-flexicar`}
                    vehiculo={vehiculo}
                    createLead={createLead}
                    getDataLocal={getDataLocal}
                  />
                ) : null}
              </div>
            </div>

            <div
              className="clearfix mb-3"
              style={{
                padding: ".7rem",
                backgroundColor: "#2C2C2C",
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <span className="tittle ml-4">{t('vehicleData')}</span>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-lg-8">
                <div>
                  {vehiculoOld?.marca_vehiculo ? 
                  <DatosVehiculosPortales
                    lead_id={lead_id}
                    className={"none"}
                    vehiculo={vehiculo}
                    setVehiculo={setVehiculo}
                    vehiculoOld={vehiculoOld}
                    />: null}
                  <DatosVehiculoClasico
                    className={"none"}
                    vehiculo={vehiculo}
                    setVehiculo={setVehiculo}
                    type={'draft'}
                    mode={vehiculo.jato ? "JATO" : "MANUAL"}
                  />
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <div className={styles.floatingLabel}>
                        <input
                          placeholder={t('kms')}
                          className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                          type="number"
                          value={state.checkLead?.kilometros}
                          onChange={(e) =>
                            handleValues(e, "kilometros", "checkLead")
                          }
                        />
                        <label for="telefono">{t('kms')}:</label>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <Checkbox
                        key={"Importado"}
                        name={t('imported')}
                        status={state.checkLead.importado}
                        onChange={() =>
                          getData_CheckBox(
                            !state.checkLead.importado,
                            "importado"
                          )

                        }
                      />
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <div className={styles.floatingLabel}>
                        <input
                          placeholder={t('vehiclePlate')}
                          className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                          type="text"
                          value={state.checkLead?.matricula}
                          onChange={(e) =>
                            handleValues(e, "matricula", "checkLead")
                          }
                        />
                        <label for="telefono">{t('vehiclePlate')}:</label>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <div className={styles.floatingLabel}>
                        <input
                          placeholder={t('frame')}
                          className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                          type="text"
                          value={state.checkLead?.bastidor}
                          onChange={(e) =>
                            handleValues(e, "bastidor", "checkLead")
                          }
                        />
                        <label for="telefono">{t('frame')}</label>
                      </div>
                    </div>
                  </div>
                  {!state.checkLead.captacion ? (
                    <>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <p>
                            {t('onlineAppraisal')}{" "}
                            {state.checkLead?.tasacion_min &&
                              state.checkLead?.tasacion_min?.toLocaleString() +
                                " € - "}
                            {state.checkLead?.tasacion_max?.toLocaleString()}
                            {" €"}
                          </p>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <p>
                            {t('priceNewVehicle')}{" "}
                            {retailPrice ? retailPrice : "-"} €
                          </p>
                        </div>
                        <small>
                          {t('extrasAppraisal')}
                        </small>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-sm-12">
                          <div className={styles.floatingLabel}>
                            <input
                              placeholder={t('preliminaryPrice')}
                              className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                              type="number"
                              disabled={!rol?.some(
                                (e) =>
                                  e === RolType.GENERAL_ADMIN ||
                                  e === RolType.CENTRAL_APPRAISER 
                              )}
                              value={state.checkLead?.tasacion_precio_preliminar}
                              onChange={(e) =>
                                handleValues(e, "tasacion_precio_preliminar", "checkLead")
                              }
                            />
                            <label for="telefono">{t('preliminaryPrice')}:</label>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <div className={styles.floatingLabel}>
                            <input
                              placeholder={t('offeredPrice')}
                              className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                              type="number"
                              disabled={!rol?.some(
                                (e) =>
                                  e === RolType.GENERAL_ADMIN ||
                                  e === RolType.CENTRAL_APPRAISER
                              )}
                              value={state.checkLead?.valoracion}
                              onChange={(e) =>
                                handleValues(e, "valoracion", "checkLead")
                              }
                            />
                            <label for="telefono">{t('offeredPrice')}:</label>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <div className={styles.floatingLabel}>
                            <input
                              placeholder={t('lookedPrice')}
                              className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                              type="number"
                              value={state.checkLead?.buscado}
                              onChange={(e) =>
                                handleValues(e, "buscado", "checkLead")
                              }
                            />
                            <label for="telefono">
                            {t('lookedPrice')}:
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 form-inline">
                          <label for="itv" style={{ fontSize: "13px" }}>
                            {t('itv')} {""}
                          </label>
                          <input
                            style={{ fontSize: "13px" }}
                            setter={"itv"}
                            id="itv"
                            placeholder={"Última ITV"}
                            className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                            type="date"
                            value={state.checkLead?.itv}
                            onChange={(e) =>
                              handleValues(e, "itv", "checkLead")
                            }
                          />
                        </div>
                      </div>{" "}
                    </>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-4 col-sm-12">
                          <div className={styles.floatingLabel}>
                            <input
                              placeholder={"Precio pactado"}
                              className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                              type="text"
                              value={state.checkLead?.pactado}
                              onChange={(e) =>
                                handleValues(e, "pactado", "checkLead")
                              }
                            />
                            <label for="telefono">Precio pactado:</label>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <div className={styles.floatingLabel}>
                            <input
                              placeholder={"Comision"}
                              className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                              type="text"
                              value={state.checkLead?.comision}
                              onChange={(e) =>
                                handleValues(e, "comision", "checkLead")
                              }
                            />
                            <label for="telefono">Comisión:</label>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12 form-inline">
                          <div className={styles.floatingLabel}>
                            <input
                              placeholder={"Adelanto"}
                              className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                              type="text"
                              value={state.checkLead?.adelanto}
                              onChange={(e) =>
                                handleValues(e, "adelanto", "checkLead")
                              }
                            />
                            <label for="telefono">Adelanto:</label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <label for="itv">ITV {""}</label>
                          <input
                            setter={"itv"}
                            id="itv"
                            placeholder={"Última ITV"}
                            className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                            type="date"
                            value={state.checkLead?.itv}
                            onChange={(e) =>
                              handleValues(e, "itv", "checkLead")
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {state.checkLead.captacion || state.checkLead.url_anuncio ? (
                    <div className="row">
                      <div className="col-md-9 col-sm-12">
                        <div className={styles.floatingLabel}>
                          <input
                            placeholder={"Anuncio"}
                            disabled={!state.checkLead.captacion ? true : false}
                            className={`form-control ds-input mt-1 ${styles.floatingInput}`}
                            type="text"
                            value={state.checkLead?.url_anuncio}
                            onChange={(e) =>
                              handleValues(e, "url_anuncio", "checkLead")
                            }
                          />
                          <label for="telefono">Anuncio:</label>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12 align-items-center">
                        <Button
                          href={state.checkLead?.url_anuncio}
                          target="_blank"
                          className={"btn-flexicar"}
                          style={{ lineHeight: "2em" }}
                        >
                          Ir al anuncio
                        </Button>
                      </div>
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <textarea
                        type="textarea"
                        placeholder={t("comments")}
                        className="form-control ds-input mt-1"
                        value={state.checkLead?.comentarios}
                        onChange={(e) =>
                          handleValues(e, "comentarios", "checkLead")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  mercedes
                    ? `col-md-12 col-sm-12 col-lg-4 ${styles.order_first}`
                    : "col-md-12 col-sm-12 col-lg-4"
                }
              >
                {state.edit_mode ? (
                  <UploadCarousel
                    images={images}
                    uploadCb={(files) => handleUploadBucketImages(files, lead_id)}
                    deleteCb={deleteBucketImage}
                    deleteAllCb={deleteAllBucketImagesForLead}
                  />
                ) : (
                  <UploadCarousel
                    images={images}
                    uploadCb={handleImagesOnMemory}
                    deleteCb={deleteImageOnMemory}
                    deleteAllCb={deleteAllImagesOnMemory}
                  />
                )}
              </div>
            </div>

            <div>
              {vehiculo?.jato ? (
                <Extras
                  disabled={!vehiculo.version ?? true}
                  //sendData={getDataAddMulti}
                  multi={true}
                  load={vehiculo?.version[0]?.value.length > 7 ? true : false}
                  query={
                    vehiculo.version && vehiculo?.version[0]
                      ? `${vehiculo.version[0].value}`
                      : null
                  }
                  className="form-control ds-input mt-1"
                  state={state}
                  setState={setState}
                  vehiculo={vehiculo}
                  setVehiculo={setVehiculo}
                  dontShowColapse={false}
                  sendData={sendData}
                  setRetailPrice={setRetailPrice}
                  retailPrice={retailPrice}
                  price={price}
                  setPrice={setPrice}
                />
              ) : null}
            </div>
            <div>
              {state.edit_mode ? (
                <EstadosVehiculo
                  lead_id={lead_id}
                  estado={estado}
                  sendEstados={sendEstados}
                />
              ) : null}
            </div>
            <div className={`m-3 row fixed-bottom ${styles.navMov}`}>
              <ModalBack createLead={createLead} />
              {state ? (
                <>
                  <Button
                    color="success"
                    className={styles.create}
                    disabled={waitForBucketUpload}
                    onClick={async () => {
                     validation()
                     }}
                  >
                    <RiSave3Fill size={30} role="button" />
                  </Button>

                  {state.edit_mode ? (
                    <>
                      <Button
                        color="warning"
                        className={mercedes ? styles.mercedes : styles.print}
                        onClick={() => setModalPrintPercha({ open: true })}
                      >
                        <BsPrinterFill size={30} />
                      </Button>

                      <Button
                        className={styles.contrato}
                        onClick={() => createLead()}
                      >
                        <MdNoteAdd/>
                      </Button>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default FormLead;
