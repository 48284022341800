import React from "react";
import { useParams } from "react-router-dom";

import TabPaneHeader from "../TabPaneHeader";

import PreparationTable from "components/PreparationTable";
import Verification from "../Verification/Verification";

const VehiclePreparation = ({vehicle}) => {


  return (
    <>
      <TabPaneHeader
        title="Registro de preparación"
        buttonType="preparacion"
        vehicle={vehicle}
      />
      <div>
        <PreparationTable vehicle={vehicle} />
      </div>
      <div>
        <Verification />
      </div>
    </>
  );
};
export default VehiclePreparation;
