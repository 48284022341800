import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Container,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabPane,
	TabContent,
	Button
} from "reactstrap";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import { QRCodeCanvas } from "qrcode.react";

import styles from "./index.module.scss";
import DatosVehiculoCarousel from "./DatosVehiculoCarousel";
import TabPaneHeader from "../TabPaneHeader";
import RolType from "constants/roles";
import { UserContext } from "contexts/UserContext";
import Loading from "views/Loading/Loading";
import ModalCommentpdf from "components/ModalCommentpdf";
import { API } from "services/urlConstants";
import { post } from "services/axiosCalls";
import errorCall from "services/errorCall";
import { getDealer } from "services/Dealers/dealers.service";
import { getFinance } from "services/Finance/finance.service";
import { errorToast } from "helpers/toastFunctions";

const ALLOWED_ROLES = [
	RolType.GENERAL_ADMIN,
	RolType.TRADING_MANAGER,
	RolType.TRADING_MANAGER_PILOT
];

const GVOTabs = [
	{
		name: "Ficha Técnica",
		param: "ficha-tecnica",
		GVOPayloadName: "GVO_FichaTecnica"
	},
	{
		name: "Exterior",
		param: "acabado-exterior",
		GVOPayloadName: "GVO_AcabadoExterior"
	},
	{
		name: "Interior",
		param: "acabado-interior",
		GVOPayloadName: "GVO_AcabadoInterior"
	},
	{
		name: "Multimedia",
		param: "multimedia-audio",
		GVOPayloadName: "GVO_MultimediaAudio"
	},
	{
		name: "Confort",
		param: "confort",
		GVOPayloadName: "GVO_Confort"
	},
	{
		name: "Seguridad",
		param: "seguridad",
		GVOPayloadName: "GVO_Seguridad"
	},
	{
		name: "Equipamiento Extra",
		param: "equipamiento-opcional",
		GVOPayloadName: "GVO_EquipamientoOpcional"
	},
	{
		name: "Enlaces",
		param: "enlaces"
	}
];

const DatosVehiculo = ({ launchModal, vehicle, toggleRemoveModal, toggleRestoreModal }) => {
	const { rol } = useContext(UserContext);
	const { id: vehicleId } = useParams();
	const { t } = useTranslation("common");

	const [isLoading, setIsLoading] = useState(true);

	let template;
	const printTemplateRef = useRef(null);

	const resetPrintTemplate = () =>
		(template = document.getElementById("template").innerHTML = <div></div>);

	const print = useReactToPrint({
		content: () => printTemplateRef.current
	});
	const [printExtraComment, setPrintExtraComment] = useState(undefined);

	const [localInfo, setLocalInfo] = useState({});
	const [photos, setPhotos] = useState([]);

	const [subtabCurrent, setSubtabCurrent] = useState(GVOTabs[0]);
	const [subtabContent, setSubtabContent] = useState(<></>);

	const [showModalPrint, setShowModalPrint] = useState(false);

	const gethtmltopdf = async () => {
		try {
			// const revisionImp = await revisionImpresion();
			const priceFinance = vehicle?.pvp_financiado ? vehicle?.pvp_financiado : vehicle?.pvp_contado;
			const cashPrice = vehicle?.pvp_contado;
			const carMonth = moment(vehicle?.fecha_matriculacion).format("M");
			const finance = await getFinance(priceFinance, vehicle?.anio, cashPrice, carMonth);
			const response = await post("/api/template", { template: 2 });
			const html = response[0]?.temp;
			const ejs = require("ejs");
			const data = {
				data: {
					url_imagen: photos[0]?.url,
					year: vehicle?.anio,
					brand: vehicle?.titulo_vehiculo,
					model: "",
					combustible: vehicle?.combustible,
					transmission: vehicle?.caja_cambios,
					version: vehicle?.version,
					km: vehicle?.kilometros,
					ecoSticker: null,
					puertas: vehicle?.puertas,
					plazas: vehicle?.plazas,
					color: vehicle?.color,
					offer: +vehicle?.pvp_anterior > 0,
					precio_contado: +vehicle?.pvp_contado,
					precio_financiado:
						+vehicle?.pvp_anterior > 0 ? +vehicle?.pvp_anterior : +vehicle?.pvp_financiado,
					precio_tachado:
						+vehicle?.pvp_anterior > 0 ? +vehicle?.pvp_anterior : +vehicle?.pvp_contado,
					precio_oferta: +vehicle?.pvp_anterior > 0 ? +vehicle?.pvp : null,
					telefono_fijo: localInfo?.telefono,
					telefono_movil: localInfo?.movil,
					email: localInfo?.email,
					direccion: localInfo?.direccion,
					concesionario: localInfo?.nombre,
					anio: vehicle?.anio,
					cambio: vehicle?.caja_cambios,
					cilindrada: vehicle?.cilindrada,
					potencia: vehicle?.potencia,
					motor:
						vehicle?.cilindrada && vehicle?.cilindrada
							? `${vehicle?.cilindrada} l / ${vehicle?.potencia} C.V.`
							: "-",
					iva_deducible: vehicle?.aplica_iva ? t("yes") : t("no"),
					consumo: vehicle?.consumo_mixto,
					finance: finance?.canFinance,
					financia_precio: finance?.quota ? finance?.quota : null,
					financia_anios: finance?.months ? Math.floor(finance?.months / 12) : null,
					matricula: vehicle?.matricula,
					bastidor: vehicle?.bastidor,
					fecha_matriculacion: moment(vehicle?.fecha_matriculacion).format("DD-MM-YYYY"),
					procedencia: vehicle?.procedencia,
					importado: "",
					comentario: printExtraComment,
					garantia: vehicle?.garantia > 0 ? t("yes") : t("no"),
					horario:
						" Lunes a Viernes: 10: 00 a 14: 00h y 16: 30 a 20: 30h  Sábado: 10: 00 a 14: 30h y 16: 30 a 20: 30h Domingo: Cerrado",
					qr: vehicle.URL ? document.getElementById("qr").toDataURL("image/png") : ""
				}
			};

			template = document.getElementById("template").innerHTML = (
				await ejs.render(html, data.data, { async: true })
			).toString();

			print();
		} catch (err) {
			errorToast(<span>{t("errors.errorPrint")}</span>);
		}
	};

	const getEquipment = useCallback(
		async (table) => {
			try {
				const callData = { table, vehicle_id: vehicleId };
				const response = await post(API.EQUIPAMIENTO, callData);
				return response.length > 0 ? response : [{ nombre: "No hay datos registrados" }];
			} catch (e) {
				errorToast(e?.response?.data?.message || "Error al obtener el equipamiento");
			}
		},
		[vehicleId]
	);

	const markupEquipment = useCallback(
		async (gvoParam) => {
			const equipment = await getEquipment(gvoParam);

			return equipment.map(({ nombre }, idx) => (
				<li
					key={idx}
					className={styles.subtabContent}
				>
					{nombre}
				</li>
			));
		},
		[getEquipment]
	);

	const getLinks = useCallback(async () => {
		const enlaces = await post(API.GET_ENLACES, vehicle.id);
		if (enlaces.length > 0) return enlaces;
	}, [vehicle.id]);

	const markupLinks = useCallback(async () => {
		const links = await getLinks();
		return (
			<>
				<img
					className="circle"
					src="/logos/flexicar_mini.png"
					alt="logo"
				/>
				{vehicle?.URL && (
					<a
						href={vehicle?.URL}
						rel="noopener noreferrer"
						target="_blank"
					>
						Ir a la Web
					</a>
				)}
				<br />
				{links?.map((enlace, idx) => {
					return (
						<span>
							<img
								alt="logo"
								className="circle"
								src={enlace.image}
							/>
							<a
								href={`${enlace.url}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								Ir a la Web
							</a>
							<br />
						</span>
					);
				})}
			</>
		);
	}, [vehicle.URL, getLinks]);

	const getVehiclePhotos = useCallback(async () => {
		try {
			const response = await post(API.GET_VEHICLE_FOTOS, {
				vehicle_id: vehicleId
			});
			setPhotos(response);
		} catch (e) {
			errorCall(e);
		}
	}, [vehicleId]);

	const getLocalInfo = useCallback(async () => {
		if (vehicle.id_local)
			try {
				const dealer = await getDealer("/dealers", vehicle.id_local);
				setLocalInfo(dealer);
			} catch (err) {
				errorToast(err.response?.data?.message || "Error al conseguir los datos del local");
			}
	}, [vehicle.id_local]);

	useEffect(() => {
		(async () => {
			await getVehiclePhotos();
			await getLocalInfo();
			setIsLoading(false);
		})();
	}, [getVehiclePhotos, getLocalInfo]);

	useEffect(() => {
		(async () => {
			const newData = subtabCurrent.GVOPayloadName
				? await markupEquipment(subtabCurrent.GVOPayloadName)
				: await markupLinks();

			setSubtabContent(newData);
		})();
	}, [subtabCurrent, markupEquipment, markupLinks]);

	if (isLoading) return <Loading />;

	return (
		<>
			<div style={{ display: "none" }}>
				<div
					ref={printTemplateRef}
					className="m-5"
				>
					{" "}
					{vehicle?.URL && (
						<QRCodeCanvas
							value={vehicle.URL}
							id="qr"
						/>
					)}
				</div>
				<div
					ref={printTemplateRef}
					className="m-5"
					id="template"
				>
					{template}
				</div>
			</div>

			<TabPaneHeader
				launchModal={launchModal}
				title="FICHA DE PRODUCTO"
				buttonType="vehicle"
				setImpresion={setShowModalPrint}
				vehicle={vehicle}
			/>

			<Container className="p-0">
				<Row className="m-0">
					<Col className="col-10 col-sm-6">
						<p>
							<span className="font-weight-bold">TÍTULO &nbsp;&nbsp;&nbsp;</span>
							<span>{vehicle && vehicle.titulo_vehiculo}</span>
							<br />
							<span className="font-weight-bold">
								ÚLTIMA MODIFICACIÓN PRECIO &nbsp;&nbsp;&nbsp;
							</span>
							<span>Hace {vehicle.ultima_actualizacion} días</span>
							<br />
							<span className="font-weight-bold">PRECIO CONTADO &nbsp;&nbsp;&nbsp;</span>
							<span>
								{Math.trunc(vehicle && vehicle.pvp_contado)
									.toLocaleString("en")
									.replace(/,/, ".")}{" "}
								€
							</span>
							<br />
							<span className="font-weight-bold">PRECIO FINANCIADO &nbsp;&nbsp;&nbsp;</span>
							<span>
								{Math.trunc(vehicle && vehicle.pvp_financiado)
									.toLocaleString("en")
									.replace(/,/, ".")}{" "}
								€
							</span>
							<br />
							<span className="font-weight-bold">MATRÍCULA &nbsp;&nbsp;&nbsp;</span>
							<span>{vehicle?.matricula}</span>
							<br />
							<span className="font-weight-bold">AÑO &nbsp;&nbsp;&nbsp;</span>
							<span>
								{vehicle?.anio}-{vehicle?.mes}
							</span>
							<br />
							<span className="font-weight-bold">KILÓMETROS &nbsp;&nbsp;&nbsp;</span>
							<span>{vehicle?.kilometros?.toLocaleString("en").replace(/,/, ".")}</span>
							<br />
							<span className="font-weight-bold">COMBUSTIBLE &nbsp;&nbsp;&nbsp;</span>
							<span>{vehicle?.combustible}</span>
							<br />
							<span className="font-weight-bold">CAJA DE CAMBIOS &nbsp;&nbsp;&nbsp;</span>
							<span>{vehicle?.transmision}</span>
							<br />
							<span className="font-weight-bold">COLOR &nbsp;&nbsp;&nbsp;</span>
							<span>{vehicle?.color}</span>
							<br />
							<span className="font-weight-bold">PUERTAS &nbsp;&nbsp;&nbsp;</span>
							<span>{vehicle?.num_puertas}</span>
							<br />
							<span className="font-weight-bold">PLAZAS &nbsp;&nbsp;&nbsp;</span>
							<span>{vehicle?.num_asientos}</span>
							<br />
							{vehicle?.v_stock !== "Autos Madrid" ? (
								<>
									<span className="font-weight-bold">TIPO DE COMPRA &nbsp;&nbsp;&nbsp;</span>
									<span>
										{
											{
												0: "Gestión de venta",
												1: "Compra Directa"
											}[vehicle.vehicle_is_owned]
										}
									</span>{" "}
								</>
							) : null}
						</p>
					</Col>
					<Col className="col-2 col-sm-1">
						<div
							className={
								"circulo-grande " +
								(vehicle?.color_estado === 1 ? "verde_activo" : "verde_inactivo")
							}
						></div>
						<div
							className={
								"circulo-grande " +
								(vehicle?.color_estado === 3 ? "amarillo_activo" : "amarillo_inactivo")
							}
						></div>
						<div
							className={
								"circulo-grande " + (vehicle?.color_estado === 2 ? "rojo_activo" : "rojo_inactivo")
							}
						></div>
					</Col>

					<Col className="col-12 col-sm-5">
						<DatosVehiculoCarousel photos={photos} />
						<p className="text-center">
							<span className="font-weight-bold ">Concesionario &nbsp;&nbsp;&nbsp;</span>
							<span>{vehicle?.nombre_local}</span>
							<br />
							<span className="font-weight-bold">Empresa Stock &nbsp;&nbsp;&nbsp;</span>
							<span>{vehicle?.v_stock}</span>
						</p>

						{ALLOWED_ROLES.some((r) => rol.includes(r)) &&
						vehicle.vehicle_status &&
						vehicle.vehicle_status !== 5 && (
							<div className="text-center">
								<Button
									onClick={() => {
										toggleRemoveModal();
									}}
								>
									{t("downGdV")}
								</Button>
							</div>)}

							{vehicle.vehicle_status === 5 && rol.includes(RolType.GENERAL_ADMIN) && (
							<div className="text-center">
								<Button
									onClick={() => {
										toggleRestoreModal();
									}}
								>
									{t("restoreVehicle")}
								</Button>
							</div>)}

							{
							  (!rol.includes(RolType.GENERAL_ADMIN) && (RolType.TRADING_MANAGER_PILOT || rol.includes(RolType.TRADING_MANAGER || rol.includes(RolType.TRADING_AGENT)))
						  ) && (
							<div className={styles.removeMessage}>
								<p>
									<b>{t("vehicleWrittenOff")}</b>
								</p>
								<p>{t("vehicleRetiredCause")}</p>
							</div>)}
							
					</Col>
				</Row>
			</Container>

			<Nav
				tabs
				className="pointer mt-2"
			>
				{GVOTabs.map((tab) => (
					<NavItem key={tab.param}>
						<NavLink
							className={subtabCurrent.param === tab.param ? "active" : ""}
							onClick={() => setSubtabCurrent(tab)}
						>
							{tab.name}
						</NavLink>
					</NavItem>
				))}
			</Nav>
			<TabContent activeTab={subtabCurrent.param}>
				<TabPane tabId={subtabCurrent.param}>
					<div className="columns3">{subtabContent}</div>
				</TabPane>
			</TabContent>

			<ModalCommentpdf
				vehiculo={vehicle}
				handleSimpleInput={(e) => setPrintExtraComment(e.target.value)}
				gethtmltopdf={gethtmltopdf}
				reset={resetPrintTemplate}
				impresion={showModalPrint}
				setImpresion={setShowModalPrint}
				type={"stock"}
			/>
		</>
	);
};
export default DatosVehiculo;
