import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Nav,
  Table
} from "reactstrap";
import styles from "./index.module.scss";


const ModalFinancial = ({ setModal, status }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <Modal
      className="modal-xl"
      centered
      isOpen={status}
      toggle={() => setModal()}
    >
      <ModalHeader>Condiciones Financiación</ModalHeader>

      <ModalBody className="p-5">
        <Nav tabs className="nav nav-pills nav-fill mb-4">
          <NavItem>
            <NavLink
              className={ activeTab === "1" ? styles.active : styles.inactive}
              onClick={() => {
                toggleTab("1");
              }}
            >
              Particulares
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={activeTab === "2" ? styles.active : styles.inactive}
              onClick={() => {
                toggleTab("2");
              }}
            >
              Empresas{" "}

            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">

              <Table className="fl-table" striped>
                <thead>
                  <tr>
                    <th>IMPORTE A FINANCIAR</th>
                    <th>LIQUIDO EN NOMINA MENSUAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Hasta 5000€</td>
                    <td>Salario mínimo (900€) en adelante</td>
                  </tr>
                  <tr>
                    <td>5.000 € a 10.000 €</td>
                    <td>De 900€ a 1200€</td>
                  </tr>
                  <tr>
                    <td>10.000€ a 15.000€</td>
                    <td>De 1.200€ a 1.600€</td>
                  </tr>
                  <tr>
                    <td>15.000€ a 20.000€</td>
                    <td>De 1.500€ a 1.750€</td>
                  </tr>
                  <tr>
                    <td>Más de 20.000€</td>
                    <td>Más de 1.500€ (a poder ser 2 titulares)</td>
                  </tr>

                </tbody>
              </Table>

            <p class="financia-part-txt"><b>Tipo de contrato</b>: Indefinido<br />
              <b>Dos titulares</b>: El riesgo se divide entre dos<br />
              <b>Autónomos</b>: Rendimiento neto anual de la renta dividido entre 12 (consultar)</p>

          </TabPane>

          <TabPane tabId="2">
            <p class="financia-emp-txt"> ❗ Para optar a financiación el plazo de financiacion mas la fecha de matriculacion del vehiculo no puede exceder los 84 meses.<br /></p>
            <div class="financia-emp-txt">
              <h5><strong>Documentación necesaria</strong></h5>
              <p>✓ CIF<br />
                ✓ DNI ADMINISTRADOR<br />
                ✓ ESCRITURA CONSTITUCIÓN (Y PODERES)<br />
                ✓ MODELO 200<br />
                ✓ BALANCE SITUACIÓN<br />
                ✓ PÉRDIDAS Y GANANCIAS<br />
                ✓ ÚLTIMOS TRES TRIMESTRES DE IVA (130, 131)<br />
                ✓ MODELO 390<br />
                ✓ JUSTIFICANTE BANCARIO<br />
                ✓ RENTA ADMINISTRADOR
              </p>
            </div>
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-light btn-outline-dark btn-flexicar"
          onClick={() => setModal()}
        >
          Cerrar
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalFinancial;
