import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./index.module.scss";
import TabPaneHeader from "../TabPaneHeader";
import { AiOutlineCheckCircle, AiOutlineInfoCircle } from "react-icons/ai";
import moment from "moment";
import { getVerification, updateVerification } from "services/Preparation/preparation.service";
import { errorToast, successToast } from "helpers/toastFunctions";
import { useParams } from "react-router-dom";
import RouteLeavingGuard from "../RouteLeavingGuard"; 
import WarningDialog from "components/WarningDialog";


const Verification = () => {
  const { id: vehicleId } = useParams();
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const [status, setStatus] = useState(false);
  const [validationInfo, setValidationInfo] = useState({});
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [verify, setVerify] = useState(false);
  const [isVerifiable, setIsVerifiable]=useState(false)

  const loadVerificationChecks = useCallback(async () => {
    try {
      const apiResponse = await getVerification(vehicleId);
      setCheckboxOptions(apiResponse.checklist);
      setDefaultValues(apiResponse.checklist);
      setStatus(apiResponse.status);
      setValidationInfo(apiResponse.validation);
      if (apiResponse.checklist.some(item => item.verified === true)) {
        setIsVerifiable(true)
       
      }


    } catch (err) {
      errorToast("Error al cargar la verificación de vehículo");
    }
  }, [vehicleId]);

  useEffect(() => {
    loadVerificationChecks();
  }, [loadVerificationChecks, vehicleId]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isDirty },
    reset,
  } = useForm({ defaultValues });

  useEffect(() => {
    reset({
      ...getValues(),
    });
    const hasCheckedItems = Object.values(getValues()).some(value => value);
    setIsVerifiable(hasCheckedItems);

  }, [checkboxOptions, getValues, reset]);

  const handleVerification = () => {
    setVerify(!verify);
    setShowVerificationModal(!showVerificationModal);
  };

  const saveVerification = async (data) => {
    const selectedCheckboxes = Object.entries(data)
      .filter(([key, value]) => typeof value === "boolean" && value === true)
      .map(([key, value]) => {
        const option = checkboxOptions.find((option) => option.name === key);
        return { id: option.id, name: key, verified: true };
      });

    const checklistIds = selectedCheckboxes.map((option) => option.id);

    try {
      await updateVerification({ vehicleId, checklistIds, verify });
      await loadVerificationChecks()
      successToast("Datos guardados correctamente")
    } catch (err) {
      errorToast("Error al actualizar la verificación de vehículo");
    }
  };

  const onSubmit = (data) => {
    saveVerification(data);
    if (verify) {
      setShowVerificationModal(!showVerificationModal);
    }
  };

  // Lógica de la guarda de navegación
  const shouldBlockNavigation = (nextLocation) => {
    return isDirty; // Bloquea la navegación si hay cambios sin guardar (isDirty es verdadero)
  };

  return (
    <div>

      <RouteLeavingGuard when={isDirty} shouldBlockNavigation={shouldBlockNavigation} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <TabPaneHeader
          title={
            <div>
              Verificación de preparación{" "}
              {status ? <AiOutlineCheckCircle /> : <AiOutlineInfoCircle />}
            </div>
          }
          buttonType="verificacion"
          vehicle={vehicleId}
          onOpenModal={() => handleVerification()}
          disabled={!isDirty || status}
          isVerifiable={isVerifiable && !status}
        />
        <p>
          Selecciona los conceptos revisados y listos para la entrega. Una vez
          verificado no se podrá modificar.
        </p>
        {status && (
          <div className={styles.infoContainer}>
            <div className={styles.text}>
              Vehículo verificado por: {validationInfo?.user?.name}
            </div>
            <div className={styles.date}>
              {moment(validationInfo?.createdAt).format("DD/MM/YYYY HH:mm")}
            </div>
          </div>
        )}
        <p><strong>Básicos</strong></p>
        <div className={styles.checkboxGrid}>
          {checkboxOptions.map((option) => (
            <div key={option.id}>
              <label>
                <input
                  type="checkbox"
                  name={option.name}
                  {...register(option.name)}
                  defaultChecked={option.verified}
                  disabled={status}
                />
                {" "}
                {option.name}
              </label>
            </div>
          ))}
        </div>
        <WarningDialog
          open={showVerificationModal}
          titleText="Verificar preparación"
          contentText=" Se va a proceder a verificar que el vehículo está preparado para la entrega al cliente. Esta acción no permite modificaciones. ¿Quieres continuar?"
          cancelButtonText="Cancelar"
          confirmButtonText="Aceptar"
          onCancel={handleVerification}
          onConfirm={handleSubmit(onSubmit)}

        />

      </form>
    </div>
  );
};

export default Verification;