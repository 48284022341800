import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import {
  Container,
} from "reactstrap";
import FormLead from "views/LeadVenta/FormLead/FormLead";
import { useParams } from "react-router-dom";


const LeadVenta = ({ props, history }) => {
  const { id_lead } = useParams();
  const { id_cliente } = useParams();

  return (
    <Container className="mt-5 mb-5">
              <FormLead
                id_lead={id_lead}
                id_cliente={id_cliente}
              />
    </Container>
  );
};

const mapStateToProps = (store, props) => ({
  props: props,
});

export default connect(mapStateToProps)(LeadVenta);
